import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { CurrencyFormat, checkIfUserAddCreditCard,getFullImageUrl, getTimeFormat, paymentDropdownItems } from "utils";
import BuzopsButton from "generic-components/BuzopsButton";
import {  Hint } from "@progress/kendo-react-labels";
import moment from "moment";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { AmountCell, NameCell,StatusDescriptionCell } from ".";
import { PaymentItemRender, PaymentValueRender } from "components/Payment/PaymentRenderItems";

const ChargeBackListView = (propData:any) => {
    const item = propData?.dataItem;
    const handleRedirection=(dataItem:any)=> {
      const data = {
        FullName: `${dataItem?.ParentName}`,
        MainMemberId:dataItem?.ParentUserMemberId,
      };
      window.parent.postMessage(data, "*");
    }
    return (
      <div className="main-grid" key={item?.ParentMemberNumber}>
        <div className="main-grid-item bz-chargeback-first-row pb-2">
          <div className="column-1">
            <div className="bz-main-provider">
              <div className="bz-provider-img">
                <img
                  src={
                    item?.ParentPhoto
                      ? getFullImageUrl(item?.ParentPhoto)
                      : "/images/user.png"
                  }
                  alt=""
                />
              </div>
              <div className="bz-provider-name">
                <h6>Client Name:   <span onClick={() => handleRedirection(item)}
                    className="btn btn-link" >{item?.ParentName}</span></h6>
                <span className="bz-provider">
                  Total Amount: {CurrencyFormat(item?.TotalAmount)}
                </span>
              </div>
            </div>
          </div>
          <div className="column-3">
            <div className="bzc-provider-name">
              <span className="pr-2">Allow Appointment/Membership use</span>
              <Checkbox
                name={"MemberStatus"}
                id={"MemberStatus"}
                value={item?.MemberStatus === 8 ? true : false}
                onChange={(e: any) =>
                    propData?.handleChange(e.value, item, "MemberStatus")
                }
              />
            </div>
          </div>
          <div className="column-4 arrow-toggle">
            <div className="bzc-provider-name">
              <span
                className={`k-icon ${item?.selectedArrow
                  ? "k-i-arrow-chevron-up "
                  : "k-i-arrow-chevron-down "
                  }cursor-pointer`}
                style={{ fontSize: "1.25rem" }}
                onClick={() =>
                    propData?.handleChange(!item?.selectedArrow, item, "selectedArrow")
                }
              ></span>
            </div>
          </div>
        </div>
        {item?.selectedArrow &&
          item.records?.map((item1: any, index: number) => {
            return (
              <>
                <div className="main-grid-item bz-chargeback-second-row pb-2">
                  <div className="column-1">
                    <div className="bzc-provider-name">
                      <small>Transaction Id</small>
                      <div className="bzc-time-sec">
                        {item1?.RelatedChargebackTransactions[0]?.TransactionId}
                        {item1?.HasRefundedTransaction && (<Hint>{`The original Transaction Id: ${item1?.RelatedChargebackTransactions[0]?.TransactionId} ${CurrencyFormat(item1?.RefundedAmount)} was already refunded on ${moment(item1?.RefundedTransactionDate).format("L")} ${getTimeFormat(item1?.RefundedTransactionDate)}.`}</Hint>)}
                      </div>
                    </div>
                  </div>
                  <div className="bz-btn-blk">
                    <div className="column-2">
                      <div className="bzc-provider-name payment-profile-dw">
                        <small>Payment Profile</small>
                        <div className="bzc-time-sec">
                          <DropDownList
                            id={`paymentProfile${item1?.ParentUserMemberId}`}
                            name={`paymentProfile${item1?.ParentUserMemberId}`}
                            data={item1?.PaymentProfiles}
                            textField="MaskedCCNumber"
                            style={{ width: "230px" }}
                            dataItemKey="PaymentProfileId"
                            valueRender={(
                              el: React.ReactElement<HTMLSpanElement>,
                              value: any
                            ) => PaymentValueRender(el, value, item1)}
                            itemRender={PaymentItemRender}
                            onChange={(e) => {
                                propData?.handleChangeSavedCard(
                                e.value,
                                item1?.ParentUserMemberId,
                                index
                              );
                            }}
                          />
                        </div>
                      </div>
                      {checkIfUserAddCreditCard() && <div className="addNewBtn">
                        <DropDownButton
                          text="Add New"
                          onItemClick={(e: any) => propData?.onCardOrBankAdd(e, item?.ParentUserMemberId)}
                          items={paymentDropdownItems()?.filter((i: any) => i?.visible === true && i?.key !=="addOther")}
                          look="flat"
                          className="btn btn-link add-new-btn"
                        />
                      </div>}
                    </div>
                    <div className="column-3">
                      <div className="bzc-provider-name">
                        <BuzopsButton
                          label={"Pay Now"}
                          onClick={() => propData?.handleConfirmPopup(item1, "Collect")}
                        />
                        <BuzopsButton
                          label={"Waive"}
                          className="mr-0"
                          onClick={() => propData?.handleConfirmPopup(item1, "Waive")}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <Grid data={item1?.RelatedChargebackTransactions} className="bz-chargeback-grid charge-back-table">
                  {/* <GridColumn field="FullName" title="Client Name" /> */}
                  {/* <GridColumn field="TransactionId" title="Transaction Id" /> */}
                  <GridColumn
                    field="TransactionDate"
                    title="Transaction Date"
                  />
                  <GridColumn
                    field="FullName"
                    title="Client Name"
                    cell={(props) => NameCell(props?.dataItem?.FullName, props?.dataItem?.MemberType)}
                  />
                  <GridColumn
                    field="PaymentMadeForDescription"
                    title="Payment Made For"
                  />
                  <GridColumn field="ServiceName" title="Service Name" />
                  <GridColumn
                    field="Amount"
                    title="Amount"
                    cell={(props: any) => AmountCell(props, "Amount")}
                  />
                  <GridColumn field="StatusDescription" title="Status"    cell={StatusDescriptionCell}/>
                </Grid>
              </>
            );
          })}
      </div>
    );
}

export default ChargeBackListView