import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import ShowBankForm from "./ShowBankForm";
import ShowCardForm from "./ShowCardForm";
import ShowDownPayment from "./ShowDownPayment";
import ServiceDetailsPanel from "./ServiceDetailsPanel";
import ClientUpdateForm from "./ClientUpdateForm";
import { CurrencyFormat } from "utils";
import { GetLocalStore } from "utils/storage";
import PurchaseClassPanel from "./PurchaseClassPanel";
import PurchasePackagePanel from "./PurchasePackagePanel";
import PaymentProfileSelection from "./PaymentProfileSelection";
import { renderFrequencyItem } from "components/package/PurchasePackageList";
import {
  Input,
  NumericTextBox,
  RadioGroup,
} from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ExpiryOnItems, ExpiryRadioItems, PackageFrequency, PackageFrequencyEnum } from "utils/form-utils";
import StripePayments from "components/stripePayment";
import SquarePaymentForm from "components/square-payment-form/paymentsv2";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import moment from "moment";
import AddClient from "components/booking/AddClient";
import ShowAddOtherPaymentMode from "./ShowAddOtherPaymentMode";
import { Upload, UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent } from "@progress/kendo-react-upload";
import { useEffect, useState } from "react";

const PaymentPanelForm = (props: any) => {
  const { card, bank,addOther } = props.paymentMethod;
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);
  
  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);


  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
          props?.handleChangeSavedCard(props.selectedPaymentProfile?.selectedPayment,{
            Reference:props?.paymentSubmitProfile?.Reference,
            ReferrenceDocument: fileData?.[0]
          })
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
      props?.handleChangeSavedCard(props.selectedPaymentProfile?.selectedPayment,{
        Reference:props?.paymentSubmitProfile?.Reference,
        ReferrenceDocument: null
      })
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
    
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };
  const endafter = [
    {
      label: "Never",
      value: 0,
    },
    {
      label: "No of occurrence",
      value: 1,
    },
  ];
  const userDetails = GetLocalStore("userDetails");
  const userConfiguration =
    userDetails?.ChargehubSquarePaymentProfile || undefined;
  const selected = props.selected;
  const { FirstName, LastName } = props.clientFormValues;
  const selectedPaymentValue = props.selectedPaymentProfile.selectedPayment;
  const ExpiryDate = props.selectedPaymentProfile.selectedPayment?.IsCreditCard
    ? props.selectedPaymentProfile.selectedPayment?.ExpiryDate
    : "";

  const { paidToday, recurrenceUnit, OcurrsEvery, installments } =
    props.downpaymentForm;

  const clientPanelTitle = () => {
    if (Object.keys(props.clientFormValues).length !== 0) {
      return `Client: ${FirstName} ${LastName}`;
    } else {
      return "Client";
    }
  };

  const purchaseServiceItem = () => {
    return (
      <ServiceDetailsPanel
        serviceData={props.origserviceData}
        staffPage={props.staffPage}
      />
    );
  };

  
  console.log('Props',props.paymentGatewayType);
  

  const PaymentGatewayForm = () => {
    switch (props.paymentGatewayType) {
      case 0:
        return (<>
          <PaymentProfileSelection
            paymentProfile={props.paymentProfile}
            handleChangeSavedCard={(val: any,dataItem=null) =>
              props.handleChangeSavedCard(val,dataItem)
            }
            page={props?.page}
            IsFromCalendar={props?.IsFromCalendar}
            checkPaymentProfileNotRequired={
              props.checkPaymentProfileNotRequired
            }
            selectedPaymentProfile={props.selectedPaymentProfile}
            paymentGatewayType={props.paymentGatewayType}
            paymentMethod={props.paymentMethod}
            handleForm={(val: string) => props.handleForm(val)}
            handleSubmit={props.handleSubmit}
            staffPage={props.staffPage}
          />
          {addOther && (
            <ShowAddOtherPaymentMode
              {...props}
              handleAddPaymentMode={props.handleAddPaymentMode}
            />
          )}</>
        );
        break;
      case 1:
        return (
          <>
            <PaymentProfileSelection
              paymentProfile={props.paymentProfile}
              handleChangeSavedCard={(val: any,dataItem=null) =>
                props.handleChangeSavedCard(val,dataItem)
              }
              page={props?.page}
              IsFromCalendar={props?.IsFromCalendar}
              checkPaymentProfileNotRequired={
                props.checkPaymentProfileNotRequired
              }
              selectedPaymentProfile={props.selectedPaymentProfile}
              paymentGatewayType={props.paymentGatewayType}
              paymentMethod={props.paymentMethod}
              handleForm={(val: string) => props.handleForm(val)}
              handleSubmit={props.handleSubmit}
              staffPage={props.staffPage}
            />
            {addOther && (
                <ShowAddOtherPaymentMode
                  {...props}
                  handleAddPaymentMode={props.handleAddPaymentMode}
                />
              )}
            {bank && (
              <ShowBankForm
                bankFormValues={props.bankFormValues}
                bankSubmitBtnLoading={props.bankSubmitBtnLoading}
                handleBankSubmit={props.handleBankSubmit}
              />
            )}
            {card && (
              <ShowCardForm
                cardFormValues={props.cardFormValues}
                cardSubmitBtnLoading={props.cardSubmitBtnLoading}
                handleCardSubmit={props.handleCardSubmit}
                cardImageIcon={props.cardImageIcon}
                handleCardImageIcon={(url: any) =>
                  props.handleCardImageIcon(url)
                }
              />
            )}
          </>
        );

      case 3:
        return (
          <>
            <PaymentProfileSelection
              paymentProfile={props.paymentProfile}
              checkPaymentProfileNotRequired={
                props.checkPaymentProfileNotRequired
              }
              handleChangeSavedCard={(val: any,dataItem=null) =>
                props.handleChangeSavedCard(val,dataItem)
              }
              page={props?.page}
              IsFromCalendar={props?.IsFromCalendar}
              selectedPaymentProfile={props.selectedPaymentProfile}
              paymentGatewayType={props.paymentGatewayType}
              paymentMethod={props.paymentMethod}
              handleForm={(val: string) => props.handleForm(val)}
              handleSubmit={props.handleSubmit}
              staffPage={props.staffPage}
            />
            {addOther && (
                <ShowAddOtherPaymentMode
                  {...props}
                  handleAddPaymentMode={props.handleAddPaymentMode}
                />
              )}
            {card && (
              <div className="pl-24 row col-12">
              <SquarePaymentForm
                UserMemberId={props?.UserMemberId}
                handleCardSubmit={props.handleCardSubmit}
                userConfiguration={userConfiguration}
                cardSubmitBtnLoading={props.cardSubmitBtnLoading}
              />
              </div>
            )}
          </>
        );
      case 4:
        return (
          <>
            <PaymentProfileSelection
              paymentProfile={props.paymentProfile}
              handleChangeSavedCard={(val: any,dataItem=null) =>
                props.handleChangeSavedCard(val,dataItem)
              }
              page={props?.page}
              checkPaymentProfileNotRequired={
                props.checkPaymentProfileNotRequired
              }
              IsFromCalendar={props?.IsFromCalendar}
              selectedPaymentProfile={props.selectedPaymentProfile}
              paymentGatewayType={props.paymentGatewayType}
              paymentMethod={props.paymentMethod}
              handleForm={(val: string) => props.handleForm(val)}
              handleSubmit={props.handleSubmit}
              staffPage={props.staffPage}
            />
            {addOther && (
                <ShowAddOtherPaymentMode
                  {...props}
                  handleAddPaymentMode={props.handleAddPaymentMode}
                />
              )}
            {bank && (
              <StripePayments
                bankFormValues={props.bankFormValues}
                bankSubmitBtnLoading={props.bankSubmitBtnLoading}
                handleBankSubmit={props.handleBankSubmit}
                type={"bank"}
                UserMemberId={props?.UserMemberId}
              />
            )}
            {card && (
              <StripePayments
                handleCardSubmit={props.handleCardSubmit}
                userConfiguration={userConfiguration}
                type={"card"}
                cardSubmitBtnLoading={props.cardSubmitBtnLoading}
                UserMemberId={props?.UserMemberId}
              />
            )}
          </>
        );

      default:
        return (
          <PaymentProfileSelection
            paymentProfile={props.paymentProfile}
            handleChangeSavedCard={(val: any,dataItem=null) =>
              props.handleChangeSavedCard(val,dataItem)
            }
            IsFromCalendar={props?.IsFromCalendar}
            page={props?.page}
            checkPaymentProfileNotRequired={
              props.checkPaymentProfileNotRequired
            }
            selectedPaymentProfile={props.selectedPaymentProfile}
            paymentGatewayType={props.paymentGatewayType}
            paymentMethod={props.paymentMethod}
            handleForm={(val: string) => props.handleForm(val)}
            handleSubmit={props.handleSubmit}
            staffPage={props.staffPage}
          />
        );
    }
  };

  const NewfirstPanel = (props: any, selected: any) => {
    if (props.page === "service") {
      return purchaseServiceItem();
    } else if (
      props.page === "inhouse-class" ||
      props.page === "online-class"
    ) {
      return (
        <PurchaseClassPanel
          session={props.session}
          PackagesData={props.PackagesData}
          ComplimentaryPermission={props?.ComplimentaryPermission}
          totalClassSessions={props?.totalClassSessions}
          clientFormValues={props.clientFormValues}
          packageCost={props?.packageCost}
          handlePackageSelectionForClass={(val: any, item: any) =>
            props?.handlePackageSelectionForClass(val, item)
          }
          handleAutoselectPackage={props?.handleAutoselectPackage}
          packageListToUse={props?.packageListToUse}
          UserMemberId={props?.UserMemberId}
          handleUseCredit={(val: any, sessionItem: any) =>
            props.handleUseCredit(val, sessionItem)
          }
          handleComplimentary={(val: any, sessionItem: any) =>
            props.handleComplimentary(val, sessionItem)
          }
          checkUseCredit={(e: any) => props.checkUseCredit(e)}
          handleRemoveSession={(item: any) => props.handleRemoveSession(item)}
          handleUseCreditSubmit={props?.handleUseCreditSubmit}
          staffPage={props.staffPage}
        />
      );
    } else if (
      props.page === "inhouse-package" ||
      props.page === "online-package"
    ) {
      return <PurchasePackagePanel package={props?.package} handlePackageReccurence={(name: string, value: any) => props?.handlePackageReccurence(name, value)} staffPage={props?.staffPage} />;
    }
  };
  const PaymentPanel = (props: any, selected: any) => {
    if (props.page === "inhouse-class" || props.page === "online-class") {
      if (
        props?.downpaymentForm?.paidToday > 0 ||
        (props?.downpaymentForm?.paidToday === 0 &&
          !props?.checkPaymentProfileNotRequired())
      ) {
        return (
          <PanelBarItem
            title={PaymentPanelTitle()}
            id="2"
            selected={selected == "2"}
            expanded={selected == "2"}
            focused={selected == "2"}
          >
            {PaymentGatewayForm()}
            {props?.paymentSubmitProfile?.CardTypeId === -2 && !props?.checkOfflineReferenceRequired() &&(
            <>
              <div className="d-flex flex-column">
                <div className="paymentdropdown col-md-6 my-2">
                  <Label>Transaction Reference Number</Label>
                  <Input
                    onChange={(event) => {
                      props?.handleChangeSavedCard(
                        props.selectedPaymentProfile?.selectedPayment,
                        {
                          Reference: event.target.value,
                          ReferrenceDocument:
                            props?.paymentSubmitProfile?.ReferrenceDocument,
                        }
                      );
                    }}
                    value={props?.paymentSubmitProfile?.Reference}
                    type={"text"}
                    style={{ width: "100%" }}
                    id={"refNumber"}
                  />
                </div>
                <div className="paymentdropdown col-md-6 my-2">
                  <Label>Reference Document</Label>
                  {/* <input type="file" /> */}
                  <Upload
                    autoUpload={false}
                    batch={false}
                    restrictions={{
                      // allowedExtensions: [".jpg", ".png", ".jpeg"],
                      maxFileSize: 1000000,
                    }}
                    showActionButtons={false}
                    multiple={false}
                    onAdd={onAdd}
                    withCredentials={false}
                    onRemove={onRemove}
                    files={originalFiles}
                  />
                </div>
              </div>
            </>
          )}
          </PanelBarItem>
        );
      }
    } else if (
      props.page === "inhouse-package" ||
      props.page === "online-package"
    ) {
      if (props?.package?.DiscountedCost > 0 || props?.package?.Cost > 0) {
        return (
          <PanelBarItem
            title={PaymentPanelTitle()}
            id="2"
            selected={selected == "2"}
            expanded={selected == "2"}
            focused={selected == "2"}
          >
            {PaymentGatewayForm()}
          </PanelBarItem>
        );
      }
    } else {
      return (
        <PanelBarItem
          title={PaymentPanelTitle()}
          id="2"
          selected={selected == "2"}
          expanded={selected == "2"}
          focused={selected == "2"}
        >
          {PaymentGatewayForm()}
        </PanelBarItem>
      );
    }
  };
  const PaymentPanelTitle = () => {
    let val = selectedPaymentValue?.MaskedCCNumber;
    let expiryDate = ExpiryDate;
    if (expiryDate === null) {
      expiryDate = "";
    }
    if (selectedPaymentValue?.CardTypeId === -1) {
      expiryDate = "";
      val = selectedPaymentValue?.CardDescriptionWithoutExpiryDate;
    }
    return selectedPaymentValue &&
      Object.keys(selectedPaymentValue).length !== 0
      ? `Payment: ${val} ${expiryDate}`
      : "Payment: Please Select / Add";
  };

  const renderPackageFrequencyTitle=()=>{
    if(props?.package?.Frequency === PackageFrequencyEnum.ONETIME){
      return (<span style={{textTransform:"capitalize"}}>{`Frequency: ${props?.package?.ExpireOn=== 8 ? "Expire On":"Expire After"} ${renderFrequencyItem(
        props.package,
        true,
        props?.originalPackage
      )}`}</span>)
    }
    return  (<span style={{textTransform:"capitalize"}}>{`Frequency: ${renderFrequencyItem(
      props.package,
      true,
      props?.originalPackage
    )}`}</span>)
  }
  const today = new Date();
  const threeMonths = new Date(today);
  threeMonths.setMonth(threeMonths.getMonth() + 3);
  const minimumDate = new Date(moment().add(1, "day").format("L"));


  return (
    <div className="checkout-panelbar-wrapper">
      {NewfirstPanel(props, selected)}
      {((props.page === "inhouse-class" &&
        props?.selectedClassesType === "bulk") ||
        props.page === "online-class") && (
        <span
          className="btn btn-link text-decoration-underline mb-2"
          onClick={() => props?.handleAddMoreClasses()}
        >
          Purchase More Group
        </span>
      )}
      <br />
      <PanelBar
        expandMode={"single"}
        onSelect={(e) => {
          props.onSelect(e);
        }}
        key={selected?.toString()}
        className="payement-panel-header"
      >
        {(props.page === "inhouse-class" ||
          props.page === "inhouse-package") && (
          <PanelBarItem
            title={clientPanelTitle()}
            id="1"
            selected={selected == "1"}
            expanded={selected == "1"}
            focused={selected == "1"}
          >
            {/* <ClientUpdateForm
              page={props.page}
              UserMemberId={props?.UserMemberId}
              clientFormValues={props.clientFormValues}
              clientsList={props.clientsList}
              handleClientSubmit={props.handleClientSubmit}
              handleClientSelectionSubmit={props.handleClientSelectionSubmit}
              clientSubmitBtnLoading={props.clientSubmitBtnLoading}
              clientSearchLoading={props.clientSearchLoading}
              handleAddClient={props.handleAddClient}
              selectedClient={props.selectedClient}
              handleChangeClient={props.handleChangeClient}
              handleClientSelecton={(val: any) =>
                props.handleClientSelecton(val)
              }
              isPaymentForm={true}
              updateSelectedClientData={props?.updateSelectedClientData}
            /> */}
            <div className="pt-3 pb-3 px-3">
            <AddClient
                  page={"inhouse-class"}
                  disabled={false}
                  clientFormValues={props.clientFormValues}
                  clientsList={props.clientsList}
                  handleClientSubmit={props.handleClientSubmit}
                  clientSubmitBtnLoading={props.clientSubmitBtnLoading}
                  clientSearchLoading={props.clientSearchLoading}
                  handleAddClient={props.handleAddClient}
                  selectedClient={props.selectedClient}
                  //selectedradioValue={selectedradioValue}
                  handleClientSelecton={(val: any) => props?.handleClientSelecton(val)}
                  checkout={false}
                  fetchClientData={props?.fetchClientData}
                  //onChangeRadioValue={handleRadioButtonChange}
                />
            </div>
          </PanelBarItem>
        )}
        {PaymentPanel(props, selected)}
        {props.page === "service" && props.serviceData.PayByRecurring && (
          <PanelBarItem
            title={
              props.showinstallmentForm
                ? `Down Payment: ${CurrencyFormat(
                    paidToday
                  )}, Installments: ${installments}, Recurrence Unit: ${
                    recurrenceUnit?.text
                  }, Recurring every: ${OcurrsEvery}`
                : `Down Payment: ${CurrencyFormat(paidToday)}`
            }
            id="3"
            className="bz-recurrence-text-item"
            selected={selected == "3"}
            expanded={selected == "3"}
            focused={selected == "3"}
            disabled={!props.serviceData.PayByRecurring}
          >
            <ShowDownPayment
              downpaymentForm={props.downpaymentForm}
              staffPage={props.staffPage}
              IsTaxSkipped={props?.IsTaxSkipped}
              serviceData={props.serviceData}
              showinstallmentForm={props.showinstallmentForm}
              handleDownPayment={(value: any, name: string) =>
                props.handleDownPayment(value, name)
              }
              page={props.page}
              tableData={props.tableData}
            />
          </PanelBarItem>
        )}
       
        {(props.page === "inhouse-package" ||
          props.page === "online-package") &&
          (props.package?.SessionType === 2 || props.package?.SessionType === 1) && (
            <PanelBarItem
              title={renderPackageFrequencyTitle()}
              id="3"
              style={{textTransform:"capitalize"}}
              className="bz-recurrence-text-item "
              selected={selected == "3"}
              expanded={selected == "3"}
              focused={selected == "3"}
              disabled={
                !props.staffPage && !props.package.CanClientOverrideOccurence
              }
            >
              <>
               
                <div className="row py-2 px-4">
                  <div className="col-md-6 pb-2">
                    <Label>Frequency *</Label>
                    <div>
                      <DropDownList
                        data={PackageFrequency}
                        textField="text"
                        dataItemKey="id"
                        style={{ width: "100%" }}
                        disabled={!props.staffPage}
                        value={
                          PackageFrequency.filter(
                            (res: any) => res.id === props?.package?.Frequency
                          )[0] || { text: "Please Select Frequency", id: 0 }
                        }
                        onChange={(e) => {
                          props?.handlePackageReccurence(
                            "Frequency",
                            e.value.id
                          );
                        }}
                      />
                    </div>
                  </div>
                  {props?.package?.Frequency === PackageFrequencyEnum.ONETIME && 
                  <>
                   <div className="col-md-6 pb-2">
                        <label>Expire</label>
                        <div style={{ paddingBottom: "8px" }}>
                          <RadioGroup
                            data={ExpiryRadioItems}
                            layout={"horizontal"}
                            // disabled={!props.staffPage}
                            disabled={!props.staffPage}
                            onChange={(e) => {
                              props?.handlePackageReccurence(
                                "Expire",
                                e.value
                              );
                            }}
                            value={props?.package?.Expire}
                          />
                        </div>
                      </div>
                      {props?.package?.Expire === 0 ? 
                  <div className="col-md-6 pb-2">
                  <div className="d-flex">
                    <label className="k-label">
                      Expiry After{" "}
                    </label>
                  </div>
                  <div className="d-flex">
                     <NumericTextBox
                        id={"ExpireAfterDaysOnPurchase"}
                        name={"ExpireAfterDaysOnPurchase"}
                        placeholder="Expiry After"
                        min={1}
                        format={"n0"}
                        width={"100%"}
                        disabled={!props.staffPage}
                        value={props?.package?.ExpireAfterDaysOnPurchase}
                        onChange={(e: any) =>
                          props?.handlePackageReccurence(
                            "ExpireAfterDaysOnPurchase",
                            e.target.value
                          )
                        }
                      />

                    <div className="input-group-append hide-no-labels">
                      <DropDownList
                        id={"ExpireOn"}
                        name={"ExpireOn"}
                        data={ExpiryOnItems}
                        textField="text"
                        style={{ width: "160px" }}
                        disabled={!props.staffPage}
                        value={
                          ExpiryOnItems?.filter(
                            (i: any) => i?.id === props?.package?.ExpireOn
                          )[0] || { id: 0, text: "" }
                        }
                        dataItemKey="id"
                        onChange={(e: any) =>
                          props?.handlePackageReccurence("ExpireOn", e.value.id)
                        }
                      />
                    </div>
                  </div>
                  {props?.package?.ExpireAfterDaysOnPurchase < 1 && (
                    <Error>
                      {"Atleast one day is required"}
                    </Error>
                  )}
                </div>: <div className="col-md-6 pb-2">
                      <Label>
                        {"Expire On"}
                      </Label>
                      <DatePicker
                        min={minimumDate}
                        width={"100%"}
                        disabled={!props.staffPage}
                        value={new Date(props?.package?.ExpiryDate)}
                        id={"ExpiryDate"}
                        name={"ExpiryDate"}
                        onChange={(e) => {
                          props?.handlePackageReccurence("ExpiryDate", e.value)
                        }}
                      />
                      {(moment(props?.package?.ExpiryDate).format('YYYY-MM-DD') === "Invalid date" || moment(props?.package?.ExpiryDate).format('YYYY-MM-DD') < moment(minimumDate).format('YYYY-MM-DD')) && <Error>{"Please provide valid Expiry Date"}</Error>}
                    </div>
                  }
                  </>
                  }
                  {props?.package?.Frequency !== PackageFrequencyEnum.ONETIME && (
                    <>
                      <div className="col-md-6 pb-2">
                        <Label>Repeat Every*</Label>
                        <div>
                          <NumericTextBox
                            id={`repeat`}
                            name={`repeat`}
                            min={1}
                            disabled={!props.staffPage}
                            width={"100%"}
                            format={'n0'}
                            value={props?.package?.RepeatFrequency}
                            onChange={(e) => {
                              props?.handlePackageReccurence(
                                "RepeatFrequency",
                                e.value
                              );
                            }}
                          />
                          {props?.package?.RepeatFrequency < 1 && (
                            <Error>
                              {"Repeat frequency should be greater than 0"}
                            </Error>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 pb-2">
                        <label>End After</label>
                        <div style={{ paddingBottom: "8px" }}>
                          <RadioGroup
                            data={endafter}
                            layout={"horizontal"}
                            disabled={!props.staffPage && !props.package.CanClientOverrideOccurence}
                            onChange={(e) => {
                              props?.handlePackageReccurence(
                                "FrequencyEndType",
                                e.value
                              );
                            }}
                            value={props?.package?.FrequencyEndType}
                          />
                        </div>
                      </div>
                      {props?.package?.FrequencyEndType === 1 && (
                        <div className="col-md-6 pb-2">
                          <label>No of occurrence</label>
                          <div>
                            <NumericTextBox
                              id={`Occurrence`}
                              name={`Occurrence`}
                              min={2}
                              width={"100%"}
                              format={'n0'}
                              disabled={!props.staffPage && !props.package.CanClientOverrideOccurence}
                              value={props?.package?.Occurrence}
                              onChange={(e) => {
                                props?.handlePackageReccurence(
                                  "Occurrence",
                                  e.value
                                );
                              }}
                            />
                          </div>
                          {props?.package?.Occurrence < 2 && (
                            <Error>
                              {"No. of occurrence should be greater than 1"}
                            </Error>
                          )}
                        </div>
                      )}
                      {props?.package?.NextRecurringDate && (
                          <div className="col-md-6 pb-2">
                            <label>Next Renewal Date</label>
                            <DatePicker
                              width={"100%"}
                              disabled={
                                true
                              }
                              value={new Date(props?.package?.NextRecurringDate)}
                            />
                          </div>)
                        }
                      </>
                  )}
                </div>
              </>
            </PanelBarItem>
          )}
      </PanelBar>
    </div>
  );
};

export default PaymentPanelForm;
