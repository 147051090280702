import React, { useEffect, useState } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { GetLocalStore } from "utils/storage";
import PaymentProfileSelection from "components/checkout/PaymentProfileSelection";
import ShowBankForm from "components/checkout/ShowBankForm";
import ShowCardForm from "components/checkout/ShowCardForm";
import CustomFieldsUpdate from "components/ManageCustomFields/CustomFieldsUpdate.view";
import StripePayments from "components/stripePayment";
import SquarePaymentForm from "components/square-payment-form/paymentsv2";
import ShowAddOtherPaymentMode from "components/checkout/ShowAddOtherPaymentMode";
import { Upload, UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent } from "@progress/kendo-react-upload";
import {  Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";

const PaymentCheckout = (props: any) => {
  const { card, bank,addOther } = props.paymentMethod;
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);
  
  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);


  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
          props?.handleChangeSavedCard(props.selectedPaymentProfile.selectedPayment,{
            Reference:props?.paymentSubmitProfile?.Reference,
            ReferrenceDocument: fileData?.[0]
          })
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
      props?.handleChangeSavedCard(props.selectedPaymentProfile.selectedPayment,{
        Reference:props?.paymentSubmitProfile?.Reference,
        ReferrenceDocument: null
      })
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
    
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };
  const userDetails = GetLocalStore("userDetails");
  const userConfiguration =
    userDetails?.ChargehubSquarePaymentProfile || undefined;
  const selected = props.selected;
  const selectedPaymentValue = props.selectedPaymentProfile.selectedPayment;
  const ExpiryDate = props.selectedPaymentProfile.selectedPayment?.IsCreditCard
    ? props.selectedPaymentProfile.selectedPayment?.ExpiryDate
    : "";

  const PaymentGatewayForm = () => {
    switch (props.paymentGatewayType) {
      case 0:
        console.log('ChargeHub')

        //PaymentGatewayType->ChargeHub
        return (<>
          <PaymentProfileSelection
            paymentProfile={props.paymentProfile}
            handleChangeSavedCard={(val: any,dataItem=null) =>
              props.handleChangeSavedCard(val,dataItem)
            }
            selectedPaymentProfile={props.selectedPaymentProfile}
            paymentGatewayType={props.paymentGatewayType}
            paymentMethod={props.paymentMethod}
            handleForm={(val: string) => props.handleForm(val)}
            handleSubmit={props.handleSubmit}
            staffPage={props.staffPage}
          />       {addOther && (
            <ShowAddOtherPaymentMode
              {...props}
              handleAddPaymentMode={props.handleAddPaymentMode}
            />
          )}
            </>
        );
        break;
      case 1:
        console.log('AuzthorizeNet')

        //PaymentGatewayType->AuzthorizeNet
        return (
          <>
            <PaymentProfileSelection
              paymentProfile={props.paymentProfile}
              handleChangeSavedCard={(val: any,dataItem=null) =>
                props.handleChangeSavedCard(val,dataItem)
              }
              selectedPaymentProfile={props.selectedPaymentProfile}
              paymentGatewayType={props.paymentGatewayType}
              paymentMethod={props.paymentMethod}
              handleForm={(val: string) => props.handleForm(val)}
              handleSubmit={props.handleSubmit}
              staffPage={props.staffPage}
            />
           {addOther && (
                <ShowAddOtherPaymentMode
                  {...props}
                  handleAddPaymentMode={props.handleAddPaymentMode}
                />
              )}
            {bank && (
              <ShowBankForm
                bankFormValues={props.bankFormValues}
                bankSubmitBtnLoading={props.bankSubmitBtnLoading}
                handleBankSubmit={props.handleBankSubmit}
              />
            )}
            {card && (
              <ShowCardForm
                cardFormValues={props.cardFormValues}
                cardSubmitBtnLoading={props.cardSubmitBtnLoading}
                handleCardSubmit={props.handleCardSubmit}
                cardImageIcon={props.cardImageIcon}
                handleCardImageIcon={(url: any) =>
                  props.handleCardImageIcon(url)
                }
              />
            )}
          </>
        );

      case 3:
        return (
          <>
            <PaymentProfileSelection
              paymentProfile={props.paymentProfile}
              handleChangeSavedCard={(val: any,dataItem=null) =>
                props.handleChangeSavedCard(val,dataItem)
              }
              selectedPaymentProfile={props.selectedPaymentProfile}
              paymentGatewayType={props.paymentGatewayType}
              paymentMethod={props.paymentMethod}
              handleForm={(val: string) => props.handleForm(val)}
              handleSubmit={props.handleSubmit}
              staffPage={props.staffPage}
            />
            {addOther && (
                <ShowAddOtherPaymentMode
                  {...props}
                  handleAddPaymentMode={props.handleAddPaymentMode}
                />
              )}
            {card && (
              <SquarePaymentForm
                handleCardSubmit={props.handleCardSubmit}
                userConfiguration={userConfiguration}
                cardSubmitBtnLoading={props.cardSubmitBtnLoading}
                UserMemberId={props?.UserMemberId}
              />
            )}
          </>
        );
      case 4:
          // console.log('Stripe')
  
          //PaymentGatewayType->AuzthorizeNet
          return (
            <>
              <PaymentProfileSelection
                paymentProfile={props.paymentProfile}
                handleChangeSavedCard={(val: any,dataItem=null) =>
                  props.handleChangeSavedCard(val,dataItem)
                }
                page={props?.page}
                checkPaymentProfileNotRequired={props.checkPaymentProfileNotRequired}
                selectedPaymentProfile={props.selectedPaymentProfile}
                paymentGatewayType={props.paymentGatewayType}
                paymentMethod={props.paymentMethod}
                handleForm={(val: string) => props.handleForm(val)}
                handleSubmit={props.handleSubmit}
                staffPage={props.staffPage}
              />
              {addOther && (
                <ShowAddOtherPaymentMode
                  {...props}
                  handleAddPaymentMode={props.handleAddPaymentMode}
                />
              )}
              {bank && (
                <StripePayments
                  bankFormValues={props.bankFormValues}
                  bankSubmitBtnLoading={props.bankSubmitBtnLoading}
                  handleBankSubmit={props.handleBankSubmit}
                  type={"bank"}
                  UserMemberId={props?.UserMemberId}
                />
              )}
              {card && (
                <StripePayments
                  handleCardSubmit={props.handleCardSubmit}
                  userConfiguration={userConfiguration}
                  cardSubmitBtnLoading={props.cardSubmitBtnLoading}
                  type={"card"}
                  UserMemberId={props?.UserMemberId}
                />
              )}
            </>
          );
          
      default:
        //PaymentGatewayType->ChargeHub
        return (
          <PaymentProfileSelection
            paymentProfile={props.paymentProfile}
            handleChangeSavedCard={(val: any,dataItem=null) =>
              props.handleChangeSavedCard(val,dataItem)
            }
            selectedPaymentProfile={props.selectedPaymentProfile}
            paymentGatewayType={props.paymentGatewayType}
            paymentMethod={props.paymentMethod}
            handleForm={(val: string) => props.handleForm(val)}
            handleSubmit={props.handleSubmit}
            staffPage={props.staffPage}
          />
        );
    }
  };

  const PaymentPanel = (props: any, selected: any) => {
    console.log(props,'propsppppppppppppppppppp')
    return (
      <PanelBarItem
        title={PaymentPanelTitle()}
        id="1"
        selected={selected == "1"}
        expanded={selected == "1"}
        focused={selected == "1"}
      >
        {PaymentGatewayForm()}
        {props?.paymentSubmitProfile?.CardTypeId === -2 && (
            <>
              <div className="d-flex flex-column">
                <div className="paymentdropdown col-md-6 my-2">
                  <Label>Transaction Reference Number</Label>
                  <Input
                    onChange={(event) => {
                      props?.handleChangeSavedCard(
                        props.selectedPaymentProfile?.selectedPayment,
                        {
                          Reference: event.target.value,
                          ReferrenceDocument:
                            props?.paymentSubmitProfile?.ReferrenceDocument,
                        }
                      );
                    }}
                    value={props?.paymentSubmitProfile?.Reference}
                    type={"text"}
                    style={{ width: "100%" }}
                    id={"refNumber"}
                  />
                </div>
                <div className="paymentdropdown col-md-6 my-2">
                  <Label>Reference Document</Label>
                  {/* <input type="file" /> */}
                  <Upload
                    autoUpload={false}
                    batch={false}
                    restrictions={{
                      // allowedExtensions: [".jpg", ".png", ".jpeg"],
                      maxFileSize: 1000000,
                    }}
                    showActionButtons={false}
                    multiple={false}
                    onAdd={onAdd}
                    withCredentials={false}
                    onRemove={onRemove}
                    files={originalFiles}
                  />
                </div>
              </div>
            </>
          )}
      </PanelBarItem>
    );
  };

  const AdditionalInformationPanel = (props: any, selected: any) => {
    return (
      <PanelBarItem
        title={`Additional Information`}
        id="3"
        selected={selected == "3"}
        expanded={selected == "3"}
        focused={selected == "3"}
      >
        <div className="px-2 py-4">
          <CustomFieldsUpdate {...props?.customFields} />
        </div>
      </PanelBarItem>
    );
  };

  const PaymentPanelTitle = () => {
    let val = selectedPaymentValue?.MaskedCCNumber;
    let expiryDate = ExpiryDate;
    if (expiryDate === null) {
      expiryDate = "";
    }
    if (selectedPaymentValue?.CardTypeId === -1) {
      expiryDate = "";
      val = selectedPaymentValue?.CardDescriptionWithoutExpiryDate;
    }
    return selectedPaymentValue &&
      Object.keys(selectedPaymentValue).length !== 0
      ? `Payment: ${val} ${expiryDate}`
      : "Payment: Please Select / Add";
  };

  return (
    <div className="checkout-panelbar-wrapper payement-panel-header">
      <PanelBar
        expandMode={"single"}
        onSelect={(e) => {
          props.onSelect(e);
        }}
        key={selected?.toString()}
      >
      {(props?.submitPlanData?.MembershipPlanDetail?.MonthlyRate > 0 || props?.submitPlanData?.SelectedAddons?.length > 0 || props?.submitPlanData?.DownPayment > 0)  && PaymentPanel(props, selected)}
      {props?.customFields?.fieldsData?.length > 0 &&  AdditionalInformationPanel(props,selected)}
      </PanelBar>
    </div>
  );
};

export default PaymentCheckout;
