import React from "react";
import moment from "moment";

const CancelledPackage = (props: any) => {
  const { cancelDetails } = props;
  console.log('propsinCancelDetails',cancelDetails);
  
  return (
    <div className="card-body p-0">
        <div className="px-4 pt-3">
          <h6 className="card-title bz-fw-600 bz-article">
          Package Details
          </h6>
          <div className="">
            <div className="bz-row no-gutters mb-3">
              <div className="col-12 col-lg-6">
                <p className="row">
                  <span className="col bz-article">Package Name</span>:{" "}
                  <span className="col">
                    {cancelDetails.Name}
                  </span>
                </p>
              </div>
              <div className="col-12 col-lg-6">
                <p className="row">
                  <span className="col bz-article">Sign Up Date</span>:{" "}
                  <span className="col">
                    {moment(cancelDetails.PurchasedOn).format("L")}
                  </span>
                </p>
              </div>
              <div className="col-12 col-lg-6">
                <p className="row">
                  <span className="col bz-article">Start Date</span>:{" "}
                  <span className="col">
                    {moment(cancelDetails.StartOn).format("L")}
                  </span>
                </p>
              </div>
              <div className="col-12 col-lg-6">
                <p className="row">
                  <span className="col bz-article">End Date</span>:{" "}
                  <span className="col">
                    {cancelDetails?.SessionType === 3 ? "N/A" : moment(cancelDetails?.ExpireOn).format("L") || "N/A"}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <hr/>
        <div className="px-4 ">
          <h6 className="card-title bz-fw-600 bz-article">
          Cancellation Details
          </h6>
          <div className="">
            <div className="bz-row no-gutters mb-3">
              <div className="col-12 col-lg-6">
                <p className="row">
                  <span className="col bz-article">Approved By</span>:{" "}
                  <span className="col">
                    {cancelDetails.ApprovedBy}
                  </span>
                </p>
              </div>
              <div className="col-12 col-lg-6">
                <p className="row">
                  <span className="col bz-article">Approved Date</span>:{" "}
                  <span className="col">
                    {moment(cancelDetails.CancellationRequestedOn).format("L")}
                  </span>
                </p>
              </div>
              <div className="col-12 col-lg-6">
                <p className="row">
                  <span className="col bz-article">Comments of Approval</span>:{" "}
                  <span className="col">
                    {cancelDetails.CancellationNotes ? cancelDetails.CancellationNotes :'NA'}
                  </span>
                </p>
              </div>
              <div className="col-12 col-lg-6">
                <p className="row">
                  <span className="col bz-article">Cancels On</span>:{" "}
                  <span className="col">
                    {cancelDetails.CancelledOn && moment(cancelDetails.ExpireOn).format("L")}
                  </span>
                </p>
              </div>
              <div className="col-12 col-lg-6">
                <p className="row">
                  <span className="col bz-article">Cancellation Date</span>:{" "}
                  <span className="col">
                  {moment(cancelDetails.CancellationRequestedOn).format("L")}
                  </span>
                </p>
              </div>
              {moment(cancelDetails.CancelledOn).format("YYYY-MM-DD") === moment(cancelDetails.ExpireOn).format("YYYY-MM-DD") && <div className="col-12 col-lg-6">
                <p className="row">
                  <span className="col bz-article">Appointments</span>:{" "}
                  <span className="col">
                    {cancelDetails?.SessionType === 2
                      ? `${cancelDetails?.SchedulesUsed} of Unlimited`
                      : `${cancelDetails?.SchedulesUsed} of ${cancelDetails?.Schedules}`}
                  </span>
                </p>
              </div>}
            </div>
          </div>
          <div></div>
        </div>
      </div>
  );
};

export default CancelledPackage;
