import { SelectedLocationType } from "components/class/ClassTypes";
import moment from "moment";
import { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import {
  CheckUserMemberStatus,
  CustomToFixed,
  checkHasTaxEnable,
  checkIfUserCanAddBankAccount,
  getUserTimeZoneLong,
} from "utils";
import { filterBy } from "@progress/kendo-data-query";
import { ManageTaxesService } from "services/managetaxes/index.service";
import { GetLocalStore } from "utils/storage";
import { ScheduleModeOptionEnum } from "utils/form-utils";
import { UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent } from "@progress/kendo-react-upload";

const useQuickCheckout = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [disableServiceDropdown, setDisableServiceDropdown] = useState(true);
  const [availServices, setAvailServices] = useState<any>([]);
  const [Taxes, setTaxes] = useState<any>([]);
  const [TotalTaxAmount, setTotalTaxAmount] = useState<number>(0);
  const [availSchedules, setAvailSchedules] = useState<any>([]);
  const [availOriginalSchedules, setAvailOriginalSchedules] = useState<any>([]);
  const [originalServices, setOriginalServices] = useState<any>([]);
  const [providerList, setProviderList] = useState<any>([]);
  const [customLoading, setCustomLoading] = useState(false);
  const [origProviderList, setOrigProviderList] = useState<any>([]);
  const subClient = parseInt(props.match?.params?.assign)
    ? props.match?.params?.assign
    : props?.userData?.UserMemberId;
  const initialValues = {
    provider: undefined,
    service: undefined,
    notes: "",
    signOffDate: new Date(moment().format()),
    schedulePicked: undefined,
    userData: { UserMemberId: subClient },
  };
  const initialApptValues = {
    provider: undefined,
    notes: "",
    signOffDate: new Date(moment().format()),
    PreviousSchedule: undefined,
  };
  const defaultAttributes = {
    ScheduleMode: 0,
    ScheduleModeOption: 0,
    InviteePhoneNumber: "",
    TraineePhoneNumber: "",
    OtherAddress: "",
    OtherMeeting: "",
  };
  const [selectedItems, setselectedItems] = useState<any>(initialValues);
  const [selectedAppt, setSelectedAppt] = useState<any>(initialApptValues);
  const [selectedPaymentProfile, setSelectedPaymentProfile] =
    useState<any>(null);
  const [paymentProfiles, setPaymentProfiles] = useState<any>([]);
  const [unAvailableList, setUnAvailableList] = useState<any>([]);
  const [UserMemberId, setUserMemberId] = useState<any>();
  const [unAvailableListDialog, setUnAvailableListDialog] = useState(false);
  const [validateBtnLoading, setValidateBtnLoading] = useState(false);
  const [submitBtnLoading, setSubmitBtnLoading] = useState(false);
  const [showAddApptDialog, setShowAddApptDialog] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [redirection, setRedirection] = useState(false);
  const [submitCustom, setSubmitCustom] = useState(false);
  const [modified, setModified] = useState(false);
  const [apptModified, setApptModified] = useState(false);
  const service = new TenantService();
  const [AllClientsList, setAllClientsList] = useState<any>([]);
  const [multipleSlots, setMultipleSlots] = useState<any>([]);
  const [Attributes, setAttributes] =
    useState<SelectedLocationType>(defaultAttributes);
  const [addApptLoading, setAddApptLoading] = useState(false);
  const [customDialog, setCustomDialog] = useState(false);
  const initialCustomService = {
    Name: "+ Add New",
    NumberOfSessions: 0,
    DurationInMinutes: 0,
    Rate: 0,
    OriginalRate: 0,
    ServiceRateId: 0,
    PrivateServiceInstanceId: 0,
  };
  const [customService, setcustomService] = useState(initialCustomService);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);

  const [addNewItem, setAddNewItem] = useState<any>(null);
  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };

  
  //upload
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);

  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);


  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
        };
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
    }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
    // if (event.affectedFiles.length > 0) {
    //     const file = event.affectedFiles[0].getRawFile?.();
    //     if (file) {
    //       // const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    //       //if (allowedFileTypes.includes(file.type)) {
    //           const reader = new FileReader();
    //           reader.onloadend = () => {
    //               setImage(reader.result as string);
    //               setCropOpen(true);
    //           };
    //           reader.readAsDataURL(file);
    //       } else {
    //           console.error("Unsupported file type. Please upload .jpeg, .jpg, or .png files only.");
    //       }
    //     // } else {
    //     //     console.error("File is undefined");
    //     // }
    // }
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };

  //


  useEffect(() => {
    console.log(selectedItems?.service?.Attributes,'selectedItems?.serviceselectedItems?.serviceselectedItems?.service')
    if (selectedItems?.service?.PrivateServiceInstanceId === 0 || (selectedItems?.service && !selectedItems?.service?.Attributes)) {
      setAttributes({
        ...defaultAttributes,ScheduleMode:8,ScheduleModeOption:ScheduleModeOptionEnum["InPersonMeetingBusinessAddress"]
      });
    } else {
      setAttributes(selectedItems?.service?.Attributes);
    }
  }, [selectedItems?.service]);
  const [validateAppointmentResult, setValidateAppointmentResult] =
    useState<any>(null);
  const [renewConfirmDialog, setRenewConfirmDialog] = useState(false);
  const maximum = new Date(moment().format());


  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog)
  }
  const handleSuccessClosePaymethodDialog = async (msg = null,modeVal = null) => {
    if (msg) {
      props?.handleNotificationMessage(msg, "success");
    }
    setShowPaymentDialog(!showPaymentDialog)
    await fetchPaymentProfiles(props?.userData?.MainUserId,modeVal)
  }

  useEffect(() => {
    if (submit || redirection) {
      let timer = 2000;
      let timer1 = setTimeout(() => {
        if(submit){
          handleRedirection()
        }else{
          props?.handleSelectionPage("quickCheckout", false);
        }
      }, timer);
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [submit,redirection]);

  useEffect(() => {
    fetchAllApis();
  }, []);



  const handleRedirection = () => {
    setselectedItems(initialValues);
    setAttributes(defaultAttributes)
    setMultipleSlots([])
    setSelectedAppt(initialApptValues)
    setcustomService(initialCustomService);
    setSelectedPaymentProfile(null)
    setModified(false);
    setApptModified(false)
    setSubmit(false)
    fetchAllApis();
  };

  

  const fetchAllApis = async () => {
    setLoading(true);
    await getAllSubMembers(props?.userData?.MainUserId);
    await fetchPaymentProfiles(props?.userData?.MainUserId);
    await fetchTrainers();
    await fetchTaxes();
    setLoading(false);
  };
  const fetchTaxes = async () => {
    const req = {};
    const taxService = new ManageTaxesService();
    const res = await taxService.getTaxes(req);
    setTaxes(res);
  };

  const getAllSubMembers = async (memberId: any) => {
    const tenantService = new TenantService();
    const res = await tenantService.GetSubMembersWithParent(memberId);
    setAllClientsList(res);
    const val = res.find((val: any) => val.UserMemberId == subClient);
    await fetchMemberServices(subClient, val);
  };

  async function fetchTrainers() {
    let result = await service.getTrainers();
    if (result) {
      setProviderList(result);
      setOrigProviderList(result);
    }
  }

  const toggleAvailibilityDialog = () => {
    setUnAvailableListDialog(!unAvailableListDialog);
  };
  const fetchMemberServices = async (
    memberNo = subClient || props?.userData?.UserMemberId,
    UserData: any = null
  ) => {
    let memberId = memberNo;
    if (UserData !== null) {
      memberId = UserData?.UserMemberId;
    }
    if (memberId) {
      let res = [];
      if (!props?.EntityId || props?.EntityId === null) {
        const req = {
          IsPaidScheduledOnly: true,
          AllowWithOutPurchaseServices: false,
          ShowOnlyComplementaryServices: false,
          UserMembers: [
            {
              UserMemberId: memberId,
            },
          ],
        };
        res = await service.getServiceInstance(req);
      } else {
        const req = {
          EntityId: props?.EntityId,
        };
        res = await service.getServiceFilter(req);
        setUserMemberId(res[0]?.UserMemberId);
        const resuest = {
          IsPaidScheduledOnly: true,
          AllowWithOutPurchaseServices: false,
          ShowOnlyComplementaryServices: false,
          UserMembers: [
            {
              UserMemberId: res[0]?.UserMemberId,
            },
          ],
        };
        memberId = res[0]?.UserMemberId;
        res = await service.getServiceInstance(resuest);
      }
      res.unshift(initialCustomService);
      if (res.length > 1) {
        setAvailServices(res);
        if ((!props?.EntityId || props?.EntityId === null) && res?.length !== 1) {
          setDisableServiceDropdown(false);
          setAvailSchedules([]);
          setAvailOriginalSchedules([]);
          setMultipleSlots([])
          setSelectedPaymentProfile(null)
          setSelectedAppt(initialApptValues)
          setcustomService(initialCustomService);
          if (UserData !== null) {
            setselectedItems({
              ...selectedItems,
              service:initialValues?.service,
              provider: initialValues?.provider,
              notes: initialValues?.notes,
              signOffDate: initialValues?.signOffDate,
              schedulePicked: initialValues?.schedulePicked,
              userData: UserData,
            });
          }
          return;
        }
        let selectedService =null
        selectedService = res.filter(
          (item: any) =>
            item?.PrivateServiceInstanceId === parseInt(props?.EntityId)
        )?.[0];
        if(!selectedService && res?.length === 1 && res[0]?.PrivateServiceInstanceId !==0){
          selectedService=res[0]
          setDisableServiceDropdown(false);
        }
        if (selectedService) {
          if (UserData !== null) {
            setselectedItems({
              ...selectedItems,
              service: selectedService,
              provider: initialValues?.provider,
              notes: initialValues?.notes,
              signOffDate: initialValues?.signOffDate,
              schedulePicked: initialValues?.schedulePicked,
              userData: UserData,
            });
            setMultipleSlots([])
            setcustomService(initialCustomService);
            setSelectedPaymentProfile(null)
            setSelectedAppt(initialApptValues)
          } else {
            setselectedItems({
              ...selectedItems,
              service: selectedService,
            });
            setMultipleSlots([])
            setSelectedAppt(initialApptValues)
            setSelectedPaymentProfile(null)
          }
          if (
            selectedService?.HasPaidSchedules &&
            selectedService?.SessionAvailableToSchedule <= 0
          ) {
            const schedulesReq = {
              FromDate: maximum,
              CanSearchThirdPartyCalendar: false,
              Providers: [],
              UserMemberId: memberId || props?.userData?.UserMemberId,
              ServiceInstanceId: selectedService?.PrivateServiceInstanceId,
              ExcludeUnavailable: true,
              Status: 1,
              ShowPaidOnly: true,
            };
            const schedulesRes = await service.getUpcomingAppointments(
              schedulesReq
            );
            setAvailSchedules(schedulesRes?.Result);
            setAvailOriginalSchedules(schedulesRes?.Result);
          }
        }
      } else {
        if (!props?.EntityId || props?.EntityId === null) {
          setDisableServiceDropdown(false);
        }
        setAvailServices([initialCustomService]);
        setAvailSchedules([]);
        setAvailOriginalSchedules([]);
        setMultipleSlots([])
        setSelectedPaymentProfile(null)
        setcustomService(initialCustomService);
        setSelectedAppt(initialApptValues)
        if (UserData !== null) {
          setselectedItems({
            ...selectedItems,
            service: initialValues?.service,
            provider: initialValues?.provider,
            notes: initialValues?.notes,
            signOffDate: initialValues?.signOffDate,
            schedulePicked: initialValues?.schedulePicked,
            userData: UserData,
          });
        }
      }
    }
  };

  const toggleApptDialog = () => {
    setSelectedAppt({...selectedAppt,notes:initialApptValues?.notes,signOffDate:initialApptValues?.signOffDate,PreviousSchedule:initialApptValues?.PreviousSchedule});
    setApptModified(false);
  };
  const handleAddApptDialog = () => {
    if (
      selectedItems?.service?.PrivateServiceInstanceId !== 0 &&
      selectedItems?.service?.HasPaidSchedules &&
      selectedItems?.service?.SessionAvailableToSchedule <= 0 &&
      multipleSlots?.length >= availOriginalSchedules?.length
    ) {
      const errorMsg = `You are adding more than existing (${availOriginalSchedules?.length}) schedules`;
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (
      selectedItems?.service?.SessionAvailableToSchedule > 1 &&
      multipleSlots?.length >=
        selectedItems?.service?.SessionAvailableToSchedule
    ) {
      const errorMsg = `You are adding more than available appointments (${selectedItems?.service?.SessionAvailableToSchedule})`;
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    setShowAddApptDialog(!showAddApptDialog);
  };

  const handleAddAppointment = async () => {
    if (
      selectedItems?.service?.PrivateServiceInstanceId !== 0 &&
      selectedItems?.service?.HasPaidSchedules &&
      selectedItems?.service?.SessionAvailableToSchedule <= 0 &&
      multipleSlots?.length >= availOriginalSchedules?.length
    ) {
      const errorMsg = `You are adding more than existing (${availOriginalSchedules?.length}) schedules`;
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    if (
      selectedItems?.service?.SessionAvailableToSchedule > 1 &&
      multipleSlots?.length >=
        selectedItems?.service?.SessionAvailableToSchedule
    ) {
      const errorMsg = `You are adding more than available appointments (${selectedItems?.service?.SessionAvailableToSchedule})`;
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    setApptModified(true);
    if (!selectedAppt?.provider) {
      return false;
    }
    if (
      selectedItems?.service?.PrivateServiceInstanceId !== 0 &&
      selectedItems?.service?.HasPaidSchedules &&
      selectedItems?.service?.SessionAvailableToSchedule <= 0 &&
      availOriginalSchedules?.length > 1 &&
      !selectedAppt?.PreviousSchedule
    ) {
      const errorMsg = `Please pick the existing schedule to add appointment`;
      props?.handleNotificationMessage(errorMsg, "error");
      return true;
    }
    let apptInfo = { ...selectedAppt };
    const apptsList = multipleSlots?.length > 0 ? [...multipleSlots] : [];
    apptsList.push(apptInfo);
    setMultipleSlots(apptsList);
    toggleApptDialog();
  };
  const handleChangeAppt = async (val: any, name: string) => {
    if(name === "PreviousSchedule"){
      if(val?.disabled){
        return
      }
      if(selectedAppt?.PreviousSchedule?.ScheduleId === val?.ScheduleId){
        setSelectedAppt({
          ...selectedAppt,
          [name]: undefined,
        });
      }else{
        setSelectedAppt({
          ...selectedAppt,
          [name]: val,
        });
      }
    }else{

      setSelectedAppt({
        ...selectedAppt,
        [name]: val,
      });
    }
  };

  const handleModifyCustomService = () => {
    setCustomDialog(!customDialog);
  };
  const handleChange = async (val: any, name: string) => {
    if (name === "userMemberId") {
      await fetchMemberServices(val.UserMemberId, val);
      return;
    }

    if (name === "service") {
      if (val?.PrivateServiceInstanceId === 0) {
        setCustomDialog(!customDialog);
        setSubmitCustom(false);
        setselectedItems({
          ...selectedItems,
          [name]: { ...val, Configured: false },
          provider: initialValues?.provider,
          notes: initialValues?.notes,
          signOffDate: initialValues?.signOffDate,
          schedulePicked: initialValues?.schedulePicked,
        });
        setMultipleSlots([]);
        setcustomService(initialCustomService);
        if(paymentProfiles.length > 0){
          const paymentProf= paymentProfiles[paymentProfiles.length - 1]
          setSelectedPaymentProfile(paymentProf)
        }else{
          setSelectedPaymentProfile(null)
        }
        setSelectedAppt(initialApptValues)
      } else {
        const selectedService = availServices?.filter(
          (item: any) =>
            item?.PrivateServiceInstanceId === val?.PrivateServiceInstanceId
        )?.[0];
        if (selectedService) {
          setselectedItems({
            ...selectedItems,
            service: selectedService,
            provider: initialValues?.provider,
            notes: initialValues?.notes,
            signOffDate: initialValues?.signOffDate,
            schedulePicked: initialValues?.schedulePicked,
          });
          if (
            selectedService?.HasPaidSchedules &&
            selectedService?.SessionAvailableToSchedule <= 0
          ) {
            const schedulesReq = {
              FromDate: maximum,
              CanSearchThirdPartyCalendar: false,
              Providers: [],
              UserMemberId: selectedItems?.UserData?.UserMemberId,
              ServiceInstanceId: selectedService?.PrivateServiceInstanceId,
              ExcludeUnavailable: true,
              Status: 1,
              ShowPaidOnly: true,
            };
            const schedulesRes = await service.getUpcomingAppointments(
              schedulesReq
            );
            setAvailSchedules(schedulesRes?.Result);
            setAvailOriginalSchedules(schedulesRes?.Result);
          }
        } else {
          setselectedItems({
            ...selectedItems,
            [name]: val,
            provider: initialValues?.provider,
            notes: initialValues?.notes,
            signOffDate: initialValues?.signOffDate,
            schedulePicked: initialValues?.schedulePicked,
          });
        }
        setcustomService(initialCustomService);
        setSubmitCustom(false);
        setMultipleSlots([]);
      }
    } else if (name === "paymentProfile") {
      setSelectedPaymentProfile(val);
    } else if (name === "CanCollectTaxes") {
      setselectedItems({
        ...selectedItems,
        service: { ...selectedItems?.service, CanCollectTaxes: val },
      });
    } else {
      setselectedItems({
        ...selectedItems,
        [name]: val,
      });
    }
  };

  const handleTaxCalculation = (purchaseAmt: number, TaxItems = Taxes) => {
    const TaxesArray = TaxItems.map((i: any) => {
      const amount = (purchaseAmt * i?.Percentage) / 100;
      const calcAmt = CustomToFixed(amount, 2);
      return {
        TaxId: i?.TaxId,
        TaxName: i?.TaxName,
        Percentage: i?.Percentage,
        Amount: calcAmt,
      };
    });
    const taxAmt = TaxesArray?.reduce((acc: number, currentValue: any) => {
      return acc + currentValue?.Amount;
    }, 0);
    setTaxes(TaxesArray);
    setTotalTaxAmount(taxAmt);
  };

  const filterChange = (e: any) => {
    const filteredData = filterData(e.filter);
    setAvailServices(filteredData);
  };

  const filterData = (filter: any) => {
    const data = originalServices.slice();
    return filterBy(data, filter);
  };

  const trainerfilterChange = (e: any) => {
    const filteredData = trainerfilterData(e.filter);
    setProviderList(filteredData);
  };

  const trainerfilterData = (filter: any) => {
    const data = origProviderList.slice();
    return filterBy(data, filter);
  };

  const handleCheckoutValidation = () => {
    if (
      !selectedItems?.service ||
      !selectedItems?.provider
    ) {
      return false;
    }
    if (
      selectedItems?.service?.HasPaidSchedules &&
      selectedItems?.service?.SessionAvailableToSchedule <= 0 &&
      !selectedItems?.schedulePicked
    ) {
      return false;
    }

    return true;
  };
  const validateAttributes = () => {
    const AttributeValuesTemp =
      Attributes && typeof Attributes === "string"
        ? JSON.parse(Attributes)
        : Attributes;
    const AttributeValues =
      Object.keys(AttributeValuesTemp).length > 0 &&
      AttributeValuesTemp.ScheduleMode;
    return AttributeValues;
  };
  const handleValidation = async () => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    if (!validateAttributes()) {
      props?.handleNotificationMessage("Please Select Location", "error");
      return;
    }
    const check = handleCheckoutValidation();
    setModified(true);
    if (!check) {
      return;
    }
    const tzvalueName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let tzlabelVal;
    if (!props?.staffPage) {
      tzlabelVal = getUserTimeZoneLong(tzvalueName);
    }
    const StartDateTime = moment(selectedItems?.signOffDate).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const StartDate = moment(selectedItems?.signOffDate).format("YYYY-MM-DD");
    const StartTime = moment(selectedItems?.signOffDate).format("YYYY-MM-DD");
    const EndDateTime = moment(StartDateTime)
      .add(selectedItems?.service?.Duration, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");

    const ScheduleReq = {
      ScheduleId: 0,
      UserAssociateId: selectedItems?.provider?.AssociateId,
      UserId: selectedItems?.provider?.UserId,
      Schedules: [
        {
          StartDateTime,
          EndDateTime,
          StartDate,
          StartTime,
        },
      ],
      UserTimeZoneId: tzlabelVal,
    };
    setValidateBtnLoading(true);
    const result = await service.validateAppointmentsOverlap(ScheduleReq);
    if (result.length > 0) {
      setValidateAppointmentResult(result[0]);
      const records = result?.filter((item: any) => item.IsAvailable === false);
      if (records.length > 0) {
        setUnAvailableListDialog(true);
        setUnAvailableList(records);

        setValidateBtnLoading(false);
      } else {
        setUnAvailableList([]);
        await handleCheckout();
        setValidateBtnLoading(false);
      }
    } else {
      setUnAvailableList([]);
      await handleCheckout();
      setValidateBtnLoading(false);
    }
  };

  const handleMultipleSignoff = async () => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    if (!validateAttributes()) {
      props?.handleNotificationMessage("Please Select Location", "error");
      return;
    }
    setModified(true);
    const AttributeValues =
      Attributes && typeof Attributes === "string"
        ? JSON.parse(Attributes)
        : Attributes;
    const tzvalueName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const maxDate = moment().format("YYYY-MM-DD HH:mm") + `:00`;
    let tzlabelVal: any;
    if (!props?.staffPage) {
      tzlabelVal = getUserTimeZoneLong(tzvalueName);
    }
    let showPickSchedules = false;
    if (
      selectedItems?.service?.HasPaidSchedules &&
      selectedItems?.service?.SessionAvailableToSchedule <= 0 &&
      availOriginalSchedules?.length > 1
    ) {
      showPickSchedules = true;
    }
    let payload: any = [];
    multipleSlots.forEach((slot: any, index: Number) => {
      const PreviousScheduleId = showPickSchedules
        ? slot?.PreviousSchedule?.ScheduleId?.toString()
        : "";
      const StartDateTime =
        moment(slot?.signOffDate).format("YYYY-MM-DD HH:mm") + `:00`;
      const EndDateTime = moment(StartDateTime)
        .add(selectedItems?.service?.Duration, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
      const req = {
        UserMemberId: selectedItems?.userData?.UserMemberId,
        HasCheckoutSession: true,
        HasSpecificService: true,
        UserId: selectedItems?.userData?.UserId,
        MemberName: selectedItems?.userData?.FullName,
        PurchasedServiceId:
          selectedItems?.service?.PrivateServiceInstanceId.toString(),
        ShowNoCheckSessionAvailableMessage: false,
        EntityId: selectedItems?.service?.PrivateServiceInstanceId,
        Status: 2,
        FromTime: StartDateTime,
        ToTime: EndDateTime,
        ScheduleInformation:
          selectedItems?.service?.HasPaidSchedules &&
          selectedItems?.service?.SessionAvailableToSchedule <= 0
            ? `Please select the existing scheduled appointment to do quick checkout.`
            : `The One-on-One has ${selectedItems?.service?.SessionAvailableToSchedule}(remaining) appointments Available for a quick check-Out, system will detect one appointment after the submit.`,
        Duration: selectedItems?.service?.Duration,
        TrainerComments: slot?.notes || "",
        CompletedDate: StartDateTime,
        MaxDate: maxDate,
        HasPrivateServiceInstance: true,
        HasPaidSchedules: true,
        TrainerName: slot?.provider?.FullName,
        ScheduledTrainerId: slot?.provider?.AssociateId,
        PreviousScheduleId: PreviousScheduleId,
        UserTimeZoneId: tzlabelVal,
        Attributes: AttributeValues,
        CanRenew: false,
      };
      payload.push(req);
    });
    setSubmitBtnLoading(true);
    const result = await service.serviceinstanceMultipleSignoff(payload);
    setSubmitBtnLoading(false);
    if (result?.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Quick Checkout successfully completed`,
        "success"
      );
      if (!props?.EntityId || props?.EntityId === null) {
        setSubmit(true);
      }else{
        setRedirection(true)
      }
    } else {
      const errorMsg =
        result?.ErrorMessages.length > 0
          ? result?.ErrorMessages[0]
          : "Error in Quick Checkout";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleCheckout = async (restrict = true, canRenew = false) => {
    if (validateAppointmentResult?.ShowRenewalConfirmation && restrict) {
      setUnAvailableListDialog(false);
      setRenewConfirmDialog(true);
      return true;
    }
    const AttributeValues =
      Attributes && typeof Attributes === "string"
        ? JSON.parse(Attributes)
        : Attributes;
    const StartDateTime =
      moment(selectedItems?.signOffDate).format("YYYY-MM-DD HH:mm") + `:00`;
    const EndDateTime = moment(StartDateTime)
      .add(selectedItems?.service?.Duration, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");
    const maxDate = moment().format("YYYY-MM-DD HH:mm") + `:00`;
    const tzvalueName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let tzlabelVal;
    if (!props?.staffPage) {
      tzlabelVal = getUserTimeZoneLong(tzvalueName);
    }
    // availability api condition
    const req = {
      UserMemberId: selectedItems?.userData?.UserMemberId,
      HasCheckoutSession: true,
      HasSpecificService: true,
      UserId: selectedItems?.userData?.UserId,
      MemberName: selectedItems?.userData?.FullName,
      PurchasedServiceId:
        selectedItems?.service?.PrivateServiceInstanceId.toString(),
      ShowNoCheckSessionAvailableMessage: false,
      EntityId: selectedItems?.service?.PrivateServiceInstanceId,
      Status: 2,
      FromTime: StartDateTime,
      ToTime: EndDateTime,
      ScheduleInformation:
        selectedItems?.service?.HasPaidSchedules &&
        selectedItems?.service?.SessionAvailableToSchedule <= 0
          ? `Please select the existing scheduled appointment to do quick checkout.`
          : `The One-on-One has ${selectedItems?.service?.SessionAvailableToSchedule}(remaining) appointments Available for a quick check-Out, system will detect one appointment after the submit.`,
      Duration: selectedItems?.service?.Duration,
      TrainerComments: selectedItems?.notes || "",
      CompletedDate: StartDateTime,
      MaxDate: maxDate,
      HasPrivateServiceInstance: true,
      HasPaidSchedules: true,
      TrainerName: selectedItems?.provider?.FullName,
      ScheduledTrainerId: selectedItems?.provider?.AssociateId,
      PreviousScheduleId: selectedItems?.schedulePicked
        ? selectedItems?.schedulePicked?.ScheduleId.toString()
        : "",
      UserTimeZoneId: tzlabelVal,
      Attributes: AttributeValues,
      CanRenew: canRenew,
    };
    // checkout api
    setSubmitBtnLoading(true);
    const result = await service.serviceinstanceSignOff(req);
    setSubmitBtnLoading(false);
    if (result?.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Quick Checkout successfully completed`,
        "success"
      );
      if (!props?.EntityId || props?.EntityId === null) {
        setSubmit(true);
      }else{
        setRedirection(true)
      }
    } else {
      const errorMsg =
        result?.ErrorMessages.length > 0
          ? result?.ErrorMessages[0]
          : "Error in Quick Checkout";
      props?.handleNotificationMessage(errorMsg, "error");
    }
    setUnAvailableListDialog(false);
    setRenewConfirmDialog(false);
  };
  const handleDeleteSlot = (index: number) => {
    const values = [...multipleSlots];
    values.splice(index, 1);
    setMultipleSlots(values);
  };
  const handleUpdateLocation = (selectedLocation: SelectedLocationType) => {
    setAttributes(selectedLocation);
  };

  const handleCancelCustom = () => {
    if (
      selectedItems?.service?.PrivateServiceInstanceId === 0 &&
      selectedItems?.service?.Configured
    ) {
    } else {
      setselectedItems({
        ...selectedItems,
        service: initialValues?.service,
      });
    }
    setCustomDialog(false);
  };

  const handleChangeCustomService = async (value: any, name: string) => {
    let val = value;
    if (
      name === "NumberOfSessions" ||
      name === "DurationInMinutes" ||
      name === "Rate"
    ) {
      val = Math.abs(value);
    }

    if (name === "NumberOfSessions" && val > 0) {
      const req = {
        ServiceTypeId: 2,
        NoOfServices: val,
        IsActive: true,
      };
      const service = new TenantService();
      setCustomLoading(true);
      const result = await service?.GetSessionCost(req);
      setCustomLoading(false);
      if (result?.ResponseCode === 100) {
        const dataItem = result?.Item;
        const rateOfAppt = dataItem?.Rate / val;
        setcustomService({
          ...customService,
          [name]: val,
          Rate: rateOfAppt,
          OriginalRate: rateOfAppt,
        });
      }
    } else {
      setcustomService({
        ...customService,
        [name]: val,
      });
    }
  };

  const handleSaveCustom = async () => {
    setSubmitCustom(true);
    if (
      customService?.DurationInMinutes > 120 ||
      customService?.DurationInMinutes <= 0 ||
      customService?.NumberOfSessions <= 0 ||
      customService?.Rate <= 0 ||
      customService?.Name === ""
    ) {
      return;
    }
    const purchaseAmt = customService?.NumberOfSessions * customService?.Rate;
    setselectedItems({
      ...selectedItems,
      service: {
        ...customService,
        ServiceRateId: 0,
        Configured: true,
        Duration: customService?.DurationInMinutes,
        purchaseCost: purchaseAmt,
        SessionAvailableToSchedule: customService?.NumberOfSessions,
        CanCollectTaxes: checkHasTaxEnable(),
      },
    });
    handleTaxCalculation(purchaseAmt);
    setCustomDialog(false);
  };

  async function fetchPaymentProfiles(userMemberId: any,modeVal=null) {
    const result = await service.paymentProfile(userMemberId);
    if(modeVal){
      const record=result?.find((i:any)=> i?.CardTypeId ===-1)
      if(record){
        const data={
          ...record,
          CardTypeId: -2,
          MaskedCCNumber: modeVal,
          CardDescription: modeVal,
          CardDescriptionWithoutExpiryDate: modeVal,
        };
        const res=[...result]
        res.push(data)
        setPaymentProfiles(JSON.parse(JSON.stringify(res)))
        setSelectedPaymentProfile(data);
      }
    }else{
      setPaymentProfiles(result || []);
    }
  }

  const handleCustomServicePurchase = async () => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", true, "client");
      return;
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage("", "error", false, "staff");
      return;
    }
    if (!validateAttributes()) {
      props?.handleNotificationMessage("Please Select Location", "error");
      return;
    }
    if (!selectedPaymentProfile) {
      props?.handleNotificationMessage(
        "Please Select Payment Profile",
        "error"
      );
      return;
    }
    setModified(true);
    const AttributeValues =
      Attributes && typeof Attributes === "string"
        ? JSON.parse(Attributes)
        : Attributes;
    const tzvalueName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const maxDate = moment().format("YYYY-MM-DD HH:mm") + `:00`;
    let tzlabelVal: any;
    if (!props?.staffPage) {
      tzlabelVal = getUserTimeZoneLong(tzvalueName);
    }
    const userDetails = GetLocalStore("userDetails");
    const SalesPersonId = userDetails?.UserSession?.UserId;
    let payload: any = [];
    multipleSlots.forEach((slot: any, index: Number) => {
      const StartDateTime =
        moment(slot?.signOffDate).format("YYYY-MM-DD HH:mm") + `:00`;
      const EndDateTime = moment(StartDateTime)
        .add(selectedItems?.service?.Duration, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");
      const req = {
        UserMemberId: selectedItems?.userData?.UserMemberId,
        HasCheckoutSession: true,
        HasSpecificService: true,
        UserId: selectedItems?.userData?.UserId,
        MemberName: selectedItems?.userData?.FullName,
        PurchasedServiceId:
          selectedItems?.service?.PrivateServiceInstanceId.toString(),
        ShowNoCheckSessionAvailableMessage: false,
        EntityId: selectedItems?.service?.PrivateServiceInstanceId,
        Status: 2,
        FromTime: StartDateTime,
        ToTime: EndDateTime,
        ScheduleInformation: "",
        Duration: selectedItems?.service?.Duration,
        TrainerComments: slot?.notes || "",
        CompletedDate: StartDateTime,
        MaxDate: maxDate,
        HasPrivateServiceInstance: true,
        HasPaidSchedules: true,
        TrainerName: slot?.provider?.FullName,
        ScheduledTrainerId: slot?.provider?.AssociateId,
        PreviousScheduleId: "",
        UserTimeZoneId: tzlabelVal,
        Attributes: AttributeValues,
        CanRenew: false,
      };
      payload.push(req);
    });

    let paymentAttributes = null;
    if (selectedPaymentProfile?.CardTypeId === -2) {
      paymentAttributes = {
        Reference: selectedItems.refNumber,
        OfflinePaymentDescription: selectedPaymentProfile?.MaskedCCNumber,
        ReferrenceDocumentName: files[0]?.FileName || "",
        ReferrenceDocumentContent: files[0]?.ImageAsBase64 || "",
      };
    }else if(selectedPaymentProfile?.CardTypeId === -1){
      paymentAttributes = {
        Reference: "",
        OfflinePaymentDescription: "Cash",
        ReferrenceDocumentName: "",
        ReferrenceDocumentContent: "",
      };
    }

    const payloadObj = {
      PaymentAttributes:paymentAttributes,
      DownPayment: selectedItems?.service?.purchaseCost,
      NextRecurringDate: "",
      IsTransactionSucced: false,
      Coupon: {},
      Client: {
        Email: selectedItems?.userData?.Email,
        FirstName: selectedItems?.userData?.FirstName,
        LastName: selectedItems?.userData?.LastName,
        PrimaryMemberId:
          selectedItems?.userData?.ParentId ||
          selectedItems?.userData?.UserMemberId,
        UserId: selectedItems?.userData?.UserId,
        UserMemberId: selectedItems?.userData?.UserMemberId,
        MemberNumber: selectedItems?.userData?.MemberNumber,
      },
      PaymentProfile: {
        CustomerProfileID: selectedPaymentProfile?.CustomerProfileID,
        PaymentProfileID: selectedPaymentProfile?.PaymentProfileID,
        PaymentGatewayPaymentProfileID:
          selectedPaymentProfile?.PaymentGatewayPaymentProfileID,
        MaskedCCNumber: selectedPaymentProfile?.MaskedCCNumber,
        CardTypeId: selectedPaymentProfile?.CardTypeId === -2 ? -1 :selectedPaymentProfile?.CardTypeId,
        CardDescription: selectedPaymentProfile?.CardDescription,
        BankAccountType: selectedPaymentProfile?.BankAccountType,
        Credit: selectedPaymentProfile?.Credit,
      },
      PaymentGatewayTransactionHistoryId: 0,
      PrivateServiceInstanceId: 0,
      MemberRecurringDetailId: 0,
      RecurringAmount: 0,
      SalesPersonId: SalesPersonId || 0,
      Service: {
        PrivateServiceId: null,
        CanCollectTaxes: false,
        NewService: true,
        PrivateServiceType: 1,
        ServiceRateId: 0,
        Name: "Custom",
        Description: "",
        NumberOfSessions: selectedItems?.service?.NumberOfSessions,
        // ShortCode: "",
        Rate: selectedItems?.service?.Rate,
        RateAfterDiscount: selectedItems?.service?.Rate,
        Duration: selectedItems?.service?.DurationInMinutes,
        IsUseWithoutPurchase: false,
        PayByRecurring: false,
        CanRecurrenceOverride: false,
        DownPayment: selectedItems?.service?.purchaseCost,
        OcurrsEvery: 0,
        RepeatFrequency: 0,
        NoOfInstallments: 0,
        CanClientOverrideRenewalOption: false,
        RenewalType: 0,
        CanRenew: false,
        packageCostAfterDiscount: selectedItems?.service?.purchaseCost,
        UserMemberId: selectedItems?.userData?.UserMemberId,
        Schedules: payload,
      },
      Signature: "",
      AgreementTemplate: "",
      TermsAndConditionAccepted: false,
      Taxes: selectedItems?.service?.CanCollectTaxes ? Taxes : [],
      CanCollectTaxes: selectedItems?.service?.CanCollectTaxes,
      TotalTaxAmount: selectedItems?.service?.CanCollectTaxes
        ? TotalTaxAmount
        : 0,
    };

    setSubmitBtnLoading(true);
    const result = await service.purchaseService(payloadObj);
    setSubmitBtnLoading(false);
    if (result?.ResponseCode === 100) {
      props?.handleNotificationMessage(
        `Quick Checkout successfully completed`,
        "success"
      );
      setSubmit(true);
    } else {
      const errorMsg =
        result?.ErrorMessages.length > 0
          ? result?.ErrorMessages[0]
          : "Error in Quick Checkout";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  return {
    selectedItems,
    toggleAvailibilityDialog,
    unAvailableList,
    submitBtnLoading,
    handleCheckout,
    toggleApptDialog,
    selectedAppt,
    maximum,
    handleChangeAppt,
    providerList,
    trainerfilterChange,
    modified,
    addApptLoading,
    handleAddAppointment,
    setRenewConfirmDialog,
    multipleSlots,
    handleDeleteSlot,
    loading,
    handleChange,
    AllClientsList,
    subClient,
    availServices,
    disableServiceDropdown,
    filterChange,
    Attributes,
    handleUpdateLocation,
    availSchedules,
    availOriginalSchedules,
    validateBtnLoading,
    submit,
    handleValidation,
    handleMultipleSignoff,
    unAvailableListDialog,
    renewConfirmDialog,
    showAddApptDialog,
    apptModified,
    handleAddApptDialog,
    customDialog,
    handleCancelCustom,
    customService,
    handleChangeCustomService,
    customLoading,
    handleSaveCustom,
    submitCustom,
    selectedPaymentProfile,
    paymentProfiles,
    handleCustomServicePurchase,
    Taxes,
    TotalTaxAmount,
    handleModifyCustomService,
    onCardOrBankAdd,
    showPaymentDialog,
    handleClosePaymethodDialog,
    addNewItem,
    handleSuccessClosePaymethodDialog,
    initialCustomService,
    redirection,
    originalFiles,
    onAdd,
    onRemove
  };
};

export default useQuickCheckout;
