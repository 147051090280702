import { Checkbox, NumericTextBox } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { Notification as Notify } from "@progress/kendo-react-notification";
import withNotification from "components/Hoc/withNotification";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import { CheckUserMemberStatus } from "utils";
import PlanAddonFreeze from "./PlanAddonFreeze";
import PlanFreezeSummary from "./PlanFreezeSummary";
import PlanUnfreeze from "./PlanUnfreeze";
import PlanWithdrawFreeze from "./PlanWithdrawFreeze";
import { CreditsErrorMessage } from "utils/form-utils";
import { UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent } from "@progress/kendo-react-upload";
const PlanFreeze = (props: any) => {
  const [freezeDetails, setFreezeDetails] = useState<any>({});
  const [unfreezeDetails, setUnFreezeDetails] = useState<any>({});
  const [withdrawDetails, setWithdrawDetails] = useState<any>({});
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [unFreezeTotalAmount, setUnFreezeTotalAmount] = useState<any>(0);
  const [paymentProfiles, setPaymentProfiles] = useState<any>([]);
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);
  const [pageType, setPageType] = useState("plan");
  const [paymentAttributes, setPaymentAttributes] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const service = new TenantService();

  useEffect(() => {
    fetchAllApis();
  }, []);

  
  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);

  const handleChangePaymentAttributes=(value:any)=> {
    setPaymentAttributes(value)
  }

  const constructFileWithBas64 = (records: any) => {
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
            handleChangePaymentAttributes(
              {
                Reference: paymentAttributes?.Reference,
                ReferrenceDocument: fileData?.[0],
              }
            )
        }
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
      handleChangePaymentAttributes({
            Reference: paymentAttributes?.Reference,
            ReferrenceDocument: null})
      }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
    
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };

  const fetchAllApis = async () => {
    setLoading(true);
    await fetchPlanSummary();
    await fetchPaymentProfiles(props?.userData?.MainUserId);
    setLoading(false);
  };

  const fetchPlanSummary = async () => {
    const req = {
      PageNumber: 0,
      PageSize: 32767,
      UserMemberId: props?.userData?.UserMemberId,
      MemberPlanId: props?.selectedPlanId,
    };
    const res = await service?.getMemberPlanSummary(req);
    setSelectedPlan(res);
    await fetchFreezeDetails(res?.UserMemberId);
  };

  const fetchFreezeDetails = async (UserMemberId: string) => {
    const MemberPlanId = props.selectedPlanId;
    const req = {
      EntityId: MemberPlanId,
      SelectedUserMemberId: props?.userData?.UserMemberId,
      UserMemberId: UserMemberId,
    };

    const result = await service.GetPlanFreezeDetails(req);
    const resultItems = result?.Result?.map((i: any) => {
      if(i?.FreezeRequestId === 0){
        return { ...i,'FreezeRate': i?.FreezeFee };
      }
      return { ...i }
    });
    const freezeDet = {
      ...result,
      Result: resultItems,
    };
    setFreezeDetails(freezeDet);
  };

  async function fetchPaymentProfiles(userMemberId: any,modeVal=null) {
    const result = await service.paymentProfile(userMemberId);
    if(modeVal){
      const record=result?.find((i:any)=> i?.CardTypeId ===-1)
      if(record){
        const data={
          ...record,
          CardTypeId: -2,
          MaskedCCNumber: modeVal,
          CardDescription: modeVal,
          CardDescriptionWithoutExpiryDate: modeVal,
        };
        const res=[...result]
        res.push(data)
        setPaymentProfiles(JSON.parse(JSON.stringify(res)))
        if(pageType === "unfreeze"){
          handleUnFreezeChange(data,"paymentProfile",0)
        }else if(pageType === "withdraw"){
          handleWithdrawChange(data,"paymentProfile",0)
        }else{
          handleChange(data, "paymentProfile",0);
        }
      }
    }else{
      setPaymentProfiles(result || []);
    }
  }

  const showErrorMessage = (key: any, mainKey: any, type = "freeze") => {
    if (type === "unfreeze") {
      if (
        key === "PaymentGatewayProfileId" &&
        unFreezeTotalAmount > 0 &&
        (!unfreezeDetails[key] || unfreezeDetails[key] === 0)
      ) {
        return <Error>{`${mainKey} is required`}</Error>;
      } else if (
        key !== "PaymentGatewayProfileId" &&
        (!unfreezeDetails[key] || unfreezeDetails[key].length <= 0)
      ) {
        return <Error>{`${mainKey} is required`}</Error>;
      }
      return <></>;
    } else if (type === "withdraw") {
      if (
        key === "PaymentGatewayProfileId" &&
        withdrawDetails?.TotalDownPayment > 0 &&
        (!withdrawDetails[key] || withdrawDetails[key] === 0)
      ) {
        return <Error>{`${mainKey} is required`}</Error>;
      } else if (
        key !== "PaymentGatewayProfileId" &&
        (!withdrawDetails[key] || withdrawDetails[key].length <= 0)
      ) {
        return <Error>{`${mainKey} is required`}</Error>;
      }
      return <></>;
    } else {
      if (
        key === "Comments" &&
        (!freezeDetails[key] || freezeDetails[key] === 0)
      ) {
        return <Error>{`Please enter comments`}</Error>;
      }else if (
        key === "PaymentGatewayProfileId" &&
        (!freezeDetails[key] || freezeDetails[key] === 0)
      ) {
        return <Error>{`${mainKey} is required`}</Error>;
      } else if (
        key !== "PaymentGatewayProfileId" && key !== "Comments" &&
        (!freezeDetails[key] || freezeDetails[key].length <= 0)
      ) {
        return <Error>{`${mainKey} is required`}</Error>;
      }
      return <></>;
    }
  };

  const handleChange = async (value: any, name: string, entityId: number) => {
    const data = JSON.parse(JSON.stringify(freezeDetails));
    let freezeDet = data;
    if (name === "paymentProfile") {
      const PaymentGatewayPaymentProfileID =
        value?.PaymentGatewayPaymentProfileID;
      freezeDet = {
        ...data,
        PaymentGatewayProfileId: PaymentGatewayPaymentProfileID,
        CardTypeId: value?.CardTypeId,
        Credit: value?.Credit,
        MaskedCCNumber: value?.MaskedCCNumber,

      };
    } else if (name === "Comments") {
      freezeDet = {
        ...data,
        [name]: value,
      };
    } else if (name === "FreezeRate") {
      const resultItems = freezeDetails?.Result?.map((i: any) => {
        if (i?.EntityId === entityId) {
          return { ...i, [name]: Math.abs(value) };
        } else {
          return { ...i };
        }
      });
      freezeDet = {
        ...data,
        Result: resultItems,
      };
    } else {
      const resultItems = freezeDetails?.Result?.map((i: any) => {
        return { ...i, [name]: value };
      });
      freezeDet = {
        ...data,
        Result: resultItems,
      };
    }
    setFreezeDetails(freezeDet);
  };
  const handleUnFreezeChange = async (
    value: any,
    name: string,
    entityId: number
  ) => {
    const data = JSON.parse(JSON.stringify(unfreezeDetails));
    let unfreezeDet = data;
    if (name === "paymentProfile") {
      const PaymentGatewayPaymentProfileID =
        value?.PaymentGatewayPaymentProfileID;
      unfreezeDet = {
        ...data,
        PaymentGatewayProfileId: PaymentGatewayPaymentProfileID,
        CardTypeId: value?.CardTypeId,
        Credit: value?.Credit,
        MaskedCCNumber: value?.MaskedCCNumber,
      };
    } else if (name === "Comments") {
      unfreezeDet = {
        ...data,
        [name]: value,
      };
    } else {
      const resultItems = unfreezeDetails?.Result?.map((i: any) => {
        if (i?.EntityId === entityId) {
          return { ...i, [name]: value };
        } else {
          return { ...i };
        }
      });
      unfreezeDet = {
        ...data,
        Result: resultItems,
      };
    }
    setUnFreezeDetails(unfreezeDet);
    await calculateTotalUnfreezeAmount(unfreezeDet);
  };
  const handleWithdrawChange = async (
    value: any,
    name: string,
    entityId: number
  ) => {
    const data = JSON.parse(JSON.stringify(withdrawDetails));
    let withdrawDet = data;
    if (name === "paymentProfile") {
      const PaymentGatewayPaymentProfileID =
        value?.PaymentGatewayPaymentProfileID;
      withdrawDet = {
        ...data,
        PaymentGatewayProfileId: PaymentGatewayPaymentProfileID,
        CardTypeId: value?.CardTypeId,
        Credit: value?.Credit,
        MaskedCCNumber: value?.MaskedCCNumber,

      };
    } else if (name === "Comments") {
      withdrawDet = {
        ...data,
        [name]: value,
      };
    } else {
      const resultItems = withdrawDetails?.Result?.map((i: any) => {
        if (i?.EntityId === entityId) {
          return { ...i, [name]: value };
        } else {
          return { ...i };
        }
      });
      withdrawDet = {
        ...data,
        Result: resultItems,
      };
    }
    setWithdrawDetails(withdrawDet);
  };

  const handleNext = async () => {
    if (!props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage('', "error", true, 'client');
      return
    } else if (props?.staffPage && !CheckUserMemberStatus(props?.userData)) {
      props?.handleNotificationMessage('', "error", false, 'staff');
      return
    }
    setPageType("final");
  };

  const handleSubmitFreeze = async () => {
    const check = handleValidation();
    if (check) {
      let dataItem={...freezeDetails}
      let attr=null
      if(freezeDetails?.CardTypeId === -2){
        attr = {
          Reference: paymentAttributes?.Reference,
          OfflinePaymentDescription: freezeDetails?.MaskedCCNumber,
          ReferrenceDocumentName: paymentAttributes?.ReferrenceDocument?.FileName || "",
          ReferrenceDocumentContent: paymentAttributes?.ReferrenceDocument?.ImageAsBase64 || "",
        };
      }else if(freezeDetails?.CardTypeId === -1){
        attr = {
          Reference: "",
          OfflinePaymentDescription: "Cash",
          ReferrenceDocumentName: "",
          ReferrenceDocumentContent: "",
        };
      }
      dataItem= {
        ...dataItem,
        PaymentAttributes:attr
      }
      setBtnLoading(true);
      const result = await service.CompletePlanFreeze(dataItem);
      setBtnLoading(false);

      if (result?.ResponseCode === 100) {
        props?.handleNotificationMessage(result?.SuccessMessage, "success");

        setPageType("plan");
        await fetchAllApis();
      } else {
        const errorMsg =
          result?.ErrorMessages.length > 0
            ? result?.ErrorMessages[0]
            : "Error in Freezing Membership";
        props?.handleNotificationMessage(errorMsg, "error");
      }
    }
  };

  const handleWithDrawFreeze = async () => {
    const MemberPlanId = props.selectedPlanId;
    const req = {
      MemberPlanId: MemberPlanId,
      SelectedUserMemberId: props?.userData?.UserMemberId,
      UserMemberId: props?.userData?.UserMemberId,
    };
    const result = await service.GetPlanWithdrawFreezeDetails(req);
    setWithdrawDetails(result);
    setPageType("withdraw");
  };
  const handleUnFreeze = async () => {
    const MemberPlanId = props.selectedPlanId;
    const req = {
      MemberPlanId: MemberPlanId,
      SelectedUserMemberId: props?.userData?.UserMemberId,
      UserMemberId: props?.userData?.UserMemberId,
    };
    const result = await service.GetPlanUnFreezeDetails(req);
    setUnFreezeDetails(result);
    await calculateTotalUnfreezeAmount(result);
    setPageType("unfreeze");
  };

  const handleSubmitUnfreeze = async () => {
    if (!unfreezeDetails?.Comments || unfreezeDetails?.Comments === "") {
      return false;
    }
    if (
      unFreezeTotalAmount > 0 &&
      (!unfreezeDetails?.PaymentGatewayProfileId ||
        unfreezeDetails?.PaymentGatewayProfileId === 0)
    ) {
      return false;
    }
    if(unfreezeDetails?.PaymentGatewayProfileId && unfreezeDetails?.CardTypeId === 8 && unFreezeTotalAmount > unfreezeDetails?.Credit){
      const errorMsg = CreditsErrorMessage
      props?.handleNotificationMessage(errorMsg, "error");
      return false
    }
    let dataItem={...unfreezeDetails}
    if(unfreezeDetails?.CardTypeId === -2){
      const attr = {
        Reference: paymentAttributes?.Reference,
        OfflinePaymentDescription: unfreezeDetails?.MaskedCCNumber,
        ReferrenceDocumentName: paymentAttributes?.ReferrenceDocument?.FileName || "",
        ReferrenceDocumentContent: paymentAttributes?.ReferrenceDocument?.ImageAsBase64 || "",
      };
      dataItem= {
        ...dataItem,
        PaymentAttributes:attr
      }
    }
    setBtnLoading(true);
    const result = await service.CompletePlanUnfreeze(dataItem);
    setBtnLoading(false);

    if (result?.ResponseCode === 100) {
      props?.handleNotificationMessage(result?.SuccessMessage, "success");
      setPageType("plan");
      await fetchAllApis();
    } else {
      const errorMsg =
        result?.ErrorMessages.length > 0
          ? result?.ErrorMessages[0]
          : "Error in UnFreeze Membership";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const handleSubmitWithdraw = async () => {
    if (!withdrawDetails?.Comments || withdrawDetails?.Comments === "") {
      return false;
    }
    if (
      withdrawDetails?.TotalDownPayment > 0 &&
      (!withdrawDetails?.PaymentGatewayProfileId ||
        withdrawDetails?.PaymentGatewayProfileId === 0)
    ) {
      return false;
    }
    if(withdrawDetails?.PaymentGatewayProfileId && withdrawDetails?.CardTypeId === 8 && withdrawDetails?.TotalDownPayment > withdrawDetails?.Credit){
      const errorMsg = CreditsErrorMessage
      props?.handleNotificationMessage(errorMsg, "error");
      return false
    }
    let dataItem={...withdrawDetails}
    if(withdrawDetails?.CardTypeId === -2){
      const attr = {
        Reference: paymentAttributes?.Reference,
        OfflinePaymentDescription: withdrawDetails?.MaskedCCNumber,
        ReferrenceDocumentName: paymentAttributes?.ReferrenceDocument?.FileName || "",
        ReferrenceDocumentContent: paymentAttributes?.ReferrenceDocument?.ImageAsBase64 || "",
      };
      dataItem= {
        ...dataItem,
        PaymentAttributes:attr
      }
    }
    setBtnLoading(true);
    const result = await service.CompletePlanWithdrawFreeze(dataItem);
    setBtnLoading(false);

    if (result?.ResponseCode === 100) {
      props?.handleNotificationMessage(result?.SuccessMessage, "success");
      setPageType("plan");
      await fetchAllApis();
    } else {
      const errorMsg =
        result?.ErrorMessages.length > 0
          ? result?.ErrorMessages[0]
          : "Error in Withdraw Freeze Membership";
      props?.handleNotificationMessage(errorMsg, "error");
    }
  };

  const calculateTotalUnfreezeAmount = async (dataItem: any) => {
    let data = { ...dataItem };
    let arrayItem: any = [];
    data?.Result?.forEach((i: any) => {
      if (i?.EntityType === 1) {
        data?.Result?.forEach((j: any) => {
          if (j.UserMemberId === i.UserMemberId) {
            arrayItem.push({ ...j, IsNeedToUnfreeze: i?.IsNeedToUnfreeze });
          }
        });
      }
    });
    const result = { ...dataItem, Result: arrayItem };
    let totalAmt = 0;
    result?.Result?.forEach((i: any) => {
      if (i?.IsNeedToCharge && i?.IsNeedToUnfreeze) {
        totalAmt += i?.UnfreezeFee;
      }
    });
    setUnFreezeDetails(result);
    setUnFreezeTotalAmount(totalAmt);
  };

  const handleValidation = () => {
    if (!freezeDetails?.Comments || freezeDetails?.Comments === "") {
      return false;
    }
    if (freezeDetails?.Result[0]?.FreezeRate > 0 &&
(!freezeDetails?.PaymentGatewayProfileId ||
      freezeDetails?.PaymentGatewayProfileId === 0)
    ) {
      return false;
    }
    if(freezeDetails?.PaymentGatewayProfileId && freezeDetails?.CardTypeId === 8 && freezeDetails?.Result[0]?.FreezeRate > freezeDetails?.Credit){
      const errorMsg = CreditsErrorMessage
      props?.handleNotificationMessage(errorMsg, "error");
      return false
    }
    return true;
  };

  if (loading) {
    return <BuzopsLoader type="list" />;
  }
  if (freezeDetails?.Result.length === 0) {
    return <div className="bz-norecords-available">{"No Data Found"}</div>;
  }
  console.log(freezeDetails,'freezeDetails')
  const selectedPaymentProfileFreeze=paymentProfiles?.find((i:any)=> i?.PaymentGatewayPaymentProfileID===freezeDetails?.PaymentGatewayProfileId && i?.CardTypeId ===freezeDetails?.CardTypeId)
  const selectedPaymentProfileUnFreeze=paymentProfiles?.find((i:any)=> i?.PaymentGatewayPaymentProfileID===unfreezeDetails?.PaymentGatewayProfileId && i?.CardTypeId ===unfreezeDetails?.CardTypeId)
  const selectedPaymentProfilewithdrawDetails=paymentProfiles?.find((i:any)=> i?.PaymentGatewayPaymentProfileID===withdrawDetails?.PaymentGatewayProfileId && i?.CardTypeId ===withdrawDetails?.CardTypeId)
  
  return (
    <>
      <div className="bz-subsection-row bz-plan-overview bz-addon-cancellation">
        <div className="left-section">
          {pageType === "unfreeze" && (
            <PlanUnfreeze
              unfreezeDetails={unfreezeDetails}
              selectedPlan={selectedPlan}
              userData={props?.userData}
              paymentProfiles={paymentProfiles}
              btnLoading={btnLoading}
              unFreezeTotalAmount={unFreezeTotalAmount}
              valueRender={(
                el: React.ReactElement<HTMLSpanElement>,
                value: any,
              ) => PaymentValueRender(el, value, selectedPaymentProfileUnFreeze)}
              itemRender={PaymentItemRender}
              showErrorMessage={(key: any, mainKey: any) =>
                showErrorMessage(key, mainKey, "unfreeze")
              }
              handleUnFreezeChange={(
                val: any,
                name: string,
                entityId: number
              ) => handleUnFreezeChange(val, name, entityId)}
              handleSubmitUnfreeze={handleSubmitUnfreeze}
              fetchPaymentProfiles={(usermemberId:any,modeVal=null)=>fetchPaymentProfiles(usermemberId,modeVal)}
              onAdd={onAdd}
              onRemove={onRemove}
              originalFiles={originalFiles}
              handleChangePaymentAttributes={handleChangePaymentAttributes}
              paymentAttributes={paymentAttributes}
            />
          )}
          {pageType === "withdraw" && (
            <PlanWithdrawFreeze
              withdrawDetails={withdrawDetails}
              userData={props?.userData}
              selectedPlan={selectedPlan}
              paymentProfiles={paymentProfiles}
              btnLoading={btnLoading}
              valueRender={(
                el: React.ReactElement<HTMLSpanElement>,
                value: any
              ) => PaymentValueRender(el, value, selectedPaymentProfilewithdrawDetails)}
              itemRender={PaymentItemRender}
              showErrorMessage={(key: any, mainKey: any) =>
                showErrorMessage(key, mainKey, "withdraw")
              }
              handleWithdrawChange={(
                val: any,
                name: string,
                entityId: number
              ) => handleWithdrawChange(val, name, entityId)}
              handleSubmitWithdraw={handleSubmitWithdraw}
              fetchPaymentProfiles={(usermemberId:any,modeVal=null)=>fetchPaymentProfiles(usermemberId,modeVal)}
              onAdd={onAdd}
              onRemove={onRemove}
              originalFiles={originalFiles}
              handleChangePaymentAttributes={handleChangePaymentAttributes}
              paymentAttributes={paymentAttributes}
            />
          )}
          {pageType === "final" && (
            <PlanFreezeSummary
              freezeDetails={freezeDetails}
              selectedPlan={selectedPlan}
              userData={props?.userData}
              staffPage={props?.staffPage}
              paymentProfiles={paymentProfiles}
              valueRender={(
                el: React.ReactElement<HTMLSpanElement>,
                value: any,
              ) => PaymentValueRender(el, value,selectedPaymentProfileFreeze)}
              itemRender={PaymentItemRender}
              showErrorMessage={(key: any, mainKey: any) =>
                showErrorMessage(key, mainKey)
              }
              handleChange={(val: any, name: string, entityId: number) =>
                handleChange(val, name, entityId)
              }
              fetchPaymentProfiles={(usermemberId:any,modeVal=null)=>fetchPaymentProfiles(usermemberId,modeVal)}
              onAdd={onAdd}
              onRemove={onRemove}
              originalFiles={originalFiles}
              handleChangePaymentAttributes={handleChangePaymentAttributes}
              paymentAttributes={paymentAttributes}
            />
          )}
          {pageType === "plan" && (
            <>
              {freezeDetails?.Result?.filter(
                (ii: any) => ii?.ServiceType === "Plan"
              ).map((dataItem: any) => {
                const {
                  IsApproved,
                  EntityType,
                  EntityName,
                  DurationType,
                  Duration,
                  EndDate,
                  FreezeStatus,
                  ApprovedBy,
                  ApprovedDate,
                  Comments,
                  FreezeStartDate,
                  ImmediateFreeze,
                  ImmediateFreezeStartDate,
                  NormalFreezeStartDate,
                  FreezeRate,
                  MaximumBillingCycleOnFreeze,
                  WithDrawAvailable,
                  UnfreezeAvailable,
                  Notification,
                  EntityId,
                } = dataItem;
                return (
                  <>
                    <h5 className="mb-3 pt-3 bz_fs_1_5">Membership Details</h5>

                    <div className="bz-mt-2rem bz-content-text-row-mobile">
                     
                    </div>

                    <>
                      <div className="bz-mb-2_5rem bz-content-text-row-mobile">
                        <div className="bz-content-text-row">
                          <div className="bz-content-title text-right">
                          Membership Name
                          </div>
                          <span className="bz-colon text-center">:</span>
                          <div className="bz-content-body">{EntityName}</div>
                        </div>
                        {EntityType === 1 && (
                          <>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Duration
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                {DurationType === 1
                                  ? `${Duration} Months`
                                  : "NA being month to month Membership"}
                              </div>
                            </div>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                End Date
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                {DurationType === 1
                                  ? moment(EndDate).format("L")
                                  : "NA being month to month Membership"}
                              </div>
                            </div>
                          </>
                        )}

                        {FreezeStatus === 2 && (
                          <>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Approved by
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                {ApprovedBy}
                              </div>
                            </div>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Approved date
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                {ApprovedDate === null
                                  ? "NA"
                                  : moment(ApprovedDate).format("L")}
                              </div>
                            </div>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Comments
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">{Comments}</div>
                            </div>
                            <div className="bz-content-text-row">
                              <div className="bz-content-title text-right">
                                Freeze Start Date
                              </div>
                              <span className="bz-colon text-center">:</span>
                              <div className="bz-content-body">
                                {FreezeStartDate === null
                                  ? "NA"
                                  : moment(FreezeStartDate).format("L")}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="bz-content-text-row bz-notify-row">
                          <div className="bz-content-title text-right"></div>
                          <span className="bz-colon text-center"></span>
                          <div className="bz-content-body">
                            <div className="bz-plan-note">
                              <p className="mb-0">
                                <Notify key="info" type={{ style: "info" }}>
                                  {Notification}
                                </Notify>
                              </p>
                            </div>
                          </div>
                        </div>
                        {(IsApproved || FreezeStatus === 2) && (
                          <>
                            {IsApproved && (
                              <>
                                <div className="bz-content-text-row align-items-start bz-chekbox-row">
                                  <div className="bz-content-title text-right">
                                    Freeze without 30-day notice
                                  </div>
                                  <span className="bz-colon text-center">
                                    :
                                  </span>
                                  <div className="bz-content-body">
                                    <Checkbox
                                      value={ImmediateFreeze}
                                      onChange={(e) =>
                                        handleChange(
                                          e.value,
                                          "ImmediateFreeze",
                                          EntityId
                                        )
                                      }
                                    />
                                    {ImmediateFreeze && (
                                      <span
                                        style={{ color: "blue" }}
                                        className="notificxation-blue mb-0"
                                      >
                                        {
                                          " Membership will freeze on the next recurring billing date."
                                        }
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="bz-content-text-row">
                                  <div className="bz-content-title text-right">
                                    {" "}
                                    Freeze Start Date
                                  </div>
                                  <span className="bz-colon text-center">
                                    :
                                  </span>
                                  <div className="bz-content-body">
                                    {ImmediateFreeze
                                      ? moment(ImmediateFreezeStartDate).format(
                                          "L"
                                        )
                                      : moment(NormalFreezeStartDate).format(
                                          "L"
                                        )}
                                  </div>
                                </div>
                                <div className="bz-content-text-row bz-notify-row">
                                  <div className="bz-content-title text-right">
                                    Freeze Rate
                                  </div>
                                  <span className="bz-colon text-center">
                                    :
                                  </span>
                                  <div className="bz-content-body">
                                    <NumericTextBox
                                      id={`FreezeRate`}
                                      name={`FreezeRate`}
                                      min={0}
                                      format={"c2"}
                                      className="k-dropdown-width-100"
                                      value={FreezeRate}
                                      onChange={(e) =>
                                        handleChange(
                                          e.target.value,
                                          "FreezeRate",
                                          EntityId
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="bz-content-text-row bz-notify-row">
                                  <div className="bz-content-title text-right">
                                    {" "}
                                    Maximum Months on Freeze
                                  </div>
                                  <span className="bz-colon text-center">
                                    :
                                  </span>
                                  <div className="bz-content-body">
                                    <NumericTextBox
                                      id={`MaximumBillingCycleOnFreeze`}
                                      name={`MaximumBillingCycleOnFreeze`}
                                      min={0}
                                      disabled={true}
                                      className="k-dropdown-width-100"
                                      value={MaximumBillingCycleOnFreeze}
                                      onChange={(e) =>
                                        handleChange(
                                          e.target.value,
                                          "MaximumBillingCycleOnFreeze",
                                          EntityId
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </>
                            )}

                            {FreezeStatus === 2 && (
                              <>
                                <div className="bz-content-text-row bz-notify-row">
                                  <div className="bz-content-title text-right"></div>
                                  <span className="bz-colon text-center"></span>
                                  <div className="bz-content-body">
                                    {WithDrawAvailable && (
                                      <BuzopsButton
                                        label={"Withdraw"}
                                        className="ml-0"
                                        loading={btnLoading}
                                        onClick={() => handleWithDrawFreeze()}
                                      />
                                    )}
                                    {UnfreezeAvailable && (
                                      <BuzopsButton
                                        label={"Unfreeze"}
                                        loading={btnLoading}
                                        onClick={() => handleUnFreeze()}
                                      />
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </>
                    <hr />
                  </>
                );
              })}

              {freezeDetails?.Result.length > 1 &&
                freezeDetails?.Result[0].FreezeStatus !== 2 && (
                  <>
                    <h5 className="mb-3 pt-3 bz_fs_1_5">Add-on Details</h5>
                    <PlanAddonFreeze
                      freezeDetails={freezeDetails}
                      userData={props?.userData}
                      showErrorMessage={(key: any, mainKey: any) =>
                        showErrorMessage(key, mainKey)
                      }
                      handleChange={(
                        val: any,
                        name: string,
                        entityId: number
                      ) => handleChange(val, name, entityId)}
                    />
                  </>
                )}
            </>
          )}
          <div className="bz-mt-2rem bz-mb-2_5rem">
            {freezeDetails?.Result[0].FreezeStatus !== 2 &&
              freezeDetails?.Result[0].IsApproved &&
              pageType === "plan" && (
                <>
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right"></div>
                    <span className="bz-colon text-center"></span>
                    <div className="bz-content-body">
                      <BuzopsButton
                        label={"Next"}
                        className={"ml-0"}
                        loading={btnLoading}
                        onClick={() => handleNext()}
                      />
                    </div>
                  </div>
                </>
              )}

            {pageType === "final" && (
              <>
                <div className="bz-content-text-row">
                  <div className="bz-content-title text-right"></div>
                  <span className="bz-colon text-center"></span>
                  <div className="bz-content-body">
                    <BuzopsButton
                      label={"Previous"}
                      onClick={() => setPageType("plan")}
                    />
                    <BuzopsButton
                      label={"Complete"}
                      loading={btnLoading}
                      onClick={() => handleSubmitFreeze()}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withNotification(PlanFreeze);
