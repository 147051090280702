import { Button, DropDownButton } from "@progress/kendo-react-buttons";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, NumericTextBox, Switch } from "@progress/kendo-react-inputs";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import BuzopsButton from "generic-components/BuzopsButton";
import moment from "moment-timezone";
import React from "react";
import { CurrencyFormat, checkHasTaxEnable, checkIfUserAddPaymentGateway, checkIfUserCanAddBankAccount, paymentDropdownItems } from "utils";
import usePayRemaingDue from "./Container/PayRemainingDue.hook";
import { Hint, Error } from "@progress/kendo-react-labels";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import {
  customDateCell,
  InstallmentAmtCell,
} from "components/checkout/ShowDownPayment";
import withNotification from "components/Hoc/withNotification";
import { Dialog } from "@progress/kendo-react-dialogs";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TooltipContentPackageService } from "components/checkout/OrderSummary";
import AdditionalPayment from "components/AdditionalPayment/AdditionalPayment";
import { WaivePackagePaymentPermission } from "utils/permission-utils";
import { Upload } from "@progress/kendo-react-upload";
export const recurrenceUnits = [
  { id: 0, text: "Please Select Frequency" },
  { id: 4, text: "Months"},
  { id: 2, text: "Days"},
];
const PayRemainingDue = (props: any) => {
  const {
    makePayment,
    IsWaivePayment,
    amount,
    handleMakePayment,
    handleWaivePayment,
    paymentProfile,
    selectedPaymentProfile,
    handleChangeSavedCard,
    payRemainingDue,
    checkPayRemainingDue,
    remDueRecurrence,
    handlePayRemainingDue,
    remDueRecurDetails,
    payDueRecurrencetable,
    submistatus,
    payRemDueSubmitLoading,
    handleReset,
    submitPayRemainigDue,
    refreshPaymentProfiles,
    Taxes,
    TotalTaxAmount,
    serviceData,
    IsTaxSkipped,
    setIsTaxSkipped,
    setShowPaymentDialog,
    showPaymentDialog,
    addNewItem,
    onCardOrBankAdd,
    setShowAdditionalPayment,
    handleChangeAdditionalPaymentProfile,
    handleAddAdditionalPayment,
    showAdditionalPayment,
    fetchPaymentProfiles,
    submitPayRemDue,
    onAdd,
    onRemove,
    originalFiles,
    refNumber,
    setRefNumber
  } = usePayRemaingDue(props);
  const tommorrow = moment().add(1, "days").format("L");
  const showPayRemaingDueErrorMessage = (key: any, mainKey: any) => {
    if (!remDueRecurDetails[key] || remDueRecurDetails[key] <= 0) {
      return <Error>{`Please provide atleast one ${mainKey}`}</Error>;
    }
    return <></>;
  };

  const showRecurrenceDetails = (tableData: any) => {
    if (tableData?.length === 0) {
      return <>{"No Installments found"}</>;
    }
    return (
      <div
        className={"installemt-grid bz-installment-details"}
        style={{ width: "268px", margin: "0px" }}
      >
        <Grid data={tableData} style={{ maxHeight: "300px", border: 0 }}>
          <Column field="DisplayDate" title={"Dates"} cell={customDateCell} />
          <Column
            field="InstallmentAmount"
            title={"Amount"}
            cell={InstallmentAmtCell}
          />
        </Grid>
      </div>
    );
  };

  const renderPayRemainingDue = () => {
    return (
      <>
        <h5
          className="mb-3 pt-3 bz_fs_1_5"
          style={{ fontSize: "1rem", fontWeight: 600 }}
        >
          Pay Remaining Due
        </h5>
        {!makePayment && (
          <>
            <div className="bz-content-text-row-new">
              <div className="bz-content-title text-right">
                Remaining Balance
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{CurrencyFormat(amount)}</div>
            </div>
            <div className="bz-content-text-row-new">
              <div className="bz-content-title text-right">{""}</div>
              <span className="bz-colon text-center"></span>
              <div className="bz-content-body">
                <span
                  className="btn btn-link"
                  onClick={() => {
                    handleMakePayment();
                  }}
                >
                  Make Payment
                </span>
              </div>
            </div>
          </>
        )}
        {makePayment && (
          <div className="bz-mt-2rem bz-mb-2_5rem update-recurrence paynow">
            {props?.staffPage && WaivePackagePaymentPermission() && (
              <div className="bz-content-text-row-new">
                <div className="bz-content-title text-right">
                  {" "}
                  Do you want to waive this payment ?
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <Switch
                    checked={IsWaivePayment}
                    disabled={payRemDueSubmitLoading || !submistatus?.payRemDue}
                    onChange={(e) => handleWaivePayment(e.value)}
                  />
                </div>
              </div>
            )}
            {props?.staffPage && !IsWaivePayment && checkHasTaxEnable() && (
              <div className="bz-content-text-row-new">
                <div className="bz-content-title text-right">Surcharges</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  <Switch
                    checked={!IsTaxSkipped}
                    disabled={payRemDueSubmitLoading || !submistatus?.payRemDue}
                    defaultChecked={false}
                    name={`IsTaxSkipped`}
                    onChange={(e: any) => setIsTaxSkipped(!IsTaxSkipped)}
                    className="mr-1 d-inline-flex"
                  />
                </div>
              </div>
            )}
            {!IsWaivePayment && (
              <>
                <div className="bz-content-text-row-new bz-notify-row">
                  <div className="bz-content-title text-right">
                    Payment Method
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <div className="d-flex">
                      <DropDownList
                        id={"selectedPaymentProfile"}
                        name={"selectedPaymentProfile"}
                        data={paymentProfile}
                        listNoDataRender={() => "No Payment Methods"}
                        textField="MaskedCCNumber"
                        dataItemKey="PaymentProfileID"
                        className={"form-control col-md-5"}
                        valueRender={(
                          el: React.ReactElement<HTMLSpanElement>,
                          value: any
                        ) =>
                          PaymentValueRender(el, value, selectedPaymentProfile)
                        }
                        itemRender={PaymentItemRender}
                        onChange={(e) => {
                          handleChangeSavedCard(e.value);
                        }}
                        style={{ width: "100%" }}
                      />
                      {checkIfUserAddPaymentGateway() && (
                        <div className="addNewBtn pl-1">
                          <DropDownButton
                            text="Add New"
                            onItemClick={(e: any) =>
                              onCardOrBankAdd(
                                e,
                                props?.userDetails?.UserMemberId
                              )
                            }
                            items={paymentDropdownItems()?.filter(
                              (i: any) => i?.visible === true
                            )}
                            look="flat"
                            className="btn btn-link add-new-btn"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {selectedPaymentProfile?.CardTypeId === -2 && (
                  <>
                       <div className="bz-content-text-row-new bz-notify-row">
                      <div className="bz-content-title text-right">
                        Transaction Reference Number
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body col-md-6 pl-0">
                          <Input
                            onChange={(event) =>
                              setRefNumber(event?.target?.value|| null)
                            }
                            value={refNumber}
                            type={"text"}
                            // style={{ width: "280px" }}
                            id={"refNumber"}
                          />
                      </div>
                    </div>
                    
                       <div className="bz-content-text-row-new bz-notify-row">
                      <div className="bz-content-title text-right">
                        Reference Document
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body col-md-6  pl-0">
                          {/* <input type="file" /> */}
                          <Upload
                            autoUpload={false}
                            batch={false}
                            restrictions={{
                              // allowedExtensions: [".jpg", ".png", ".jpeg"],
                              maxFileSize: 1000000,
                            }}
                            showActionButtons={false}
                            multiple={false}
                            onAdd={onAdd}
                            withCredentials={false}
                            onRemove={onRemove}
                            files={originalFiles}
                          />
                        </div>
                    </div>
                  </>
                )}
              </>
            )}

            <div className="bz-content-text-row-new">
              <div className="bz-content-title text-right">Amount</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <NumericTextBox
                  className={"form-control col-md-6"}
                  value={payRemainingDue}
                  format="c2"
                  min={0}
                  disabled={IsWaivePayment}
                  onChange={(e) => {
                    checkPayRemainingDue(e.target.value);
                  }}
                />
                {amount - payRemainingDue > 0 && (
                  <Hint>
                    <span className="form-text text-muted col-md-6 pl-0">
                      Remaining Amount:{" "}
                      {CurrencyFormat(amount - payRemainingDue)}
                    </span>
                  </Hint>
                )}
                {/* {serviceData?.FailedTransactionAmount > 0 && <Hint>
                <span className="form-text text-muted col-md-6 pl-0">
                  Failed Transaction Amount
                </span>
              </Hint>} */}
              </div>
            </div>
            {!IsWaivePayment && remDueRecurrence && (
              <>
                <div className="bz-content-text-row-new bz-notify-row">
                  <div className="bz-content-title text-right">
                    Recurrence Start Date
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {" "}
                    <DatePicker
                      value={
                        remDueRecurDetails?.NextRecurrence
                          ? new Date(remDueRecurDetails?.NextRecurrence)
                          : remDueRecurDetails?.NextRecurrence
                      }
                      min={new Date(tommorrow)}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{
                        year: "yyyy",
                        month: "mm",
                        day: "dd",
                      }}
                      onChange={(e) =>
                        handlePayRemainingDue(e.target.value, "NextRecurrence")
                      }
                      className={"form-control col-md-6"}
                    />
                    {submitPayRemDue &&
                      (!remDueRecurDetails?.NextRecurrence ||
                        moment(remDueRecurDetails?.NextRecurrence).format(
                          "L"
                        ) === "Invalid date") && (
                        <Error>
                          {"Please select valid Recurrence Start Date"}
                        </Error>
                      )}
                  </div>
                </div>

                <div className="bz-content-text-row-new bz-notify-row">
                  <div className="bz-content-title text-right">Frequency</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    <DropDownList
                      data={recurrenceUnits}
                      textField="text"
                      className={"form-control col-md-6"}
                      dataItemKey="id"
                      value={
                        remDueRecurDetails?.recurrenceUnit || {
                          id: 0,
                          text: "Please Select Frequency",
                        }
                      }
                      onChange={(e) =>
                        handlePayRemainingDue(e.value, "recurrenceUnit")
                      }
                    />
                    {submitPayRemDue &&
                      remDueRecurDetails?.recurrenceUnit?.id === 0 && (
                        <Error>{`Please Select Frequency`}</Error>
                      )}
                  </div>
                </div>
                <div className="bz-content-text-row-new bz-notify-row">
                  <div className="bz-content-title text-right">
                    Recurring Every
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {" "}
                    <input
                      type="number"
                      min="0"
                      className={"form-control col-md-6"}
                      value={remDueRecurDetails?.OccursEvery}
                      onChange={(e) =>
                        handlePayRemainingDue(e.target.value, "OccursEvery")
                      }
                    />
                    {submitPayRemDue &&
                      showPayRemaingDueErrorMessage(
                        "OccursEvery",
                        "Recurring Every"
                      )}
                  </div>
                </div>
                <div className="bz-content-text-row-new">
                  <div className="bz-content-title text-right">
                    Number Of Installments
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {" "}
                    <input
                      type="number"
                      min="0"
                      max="999"
                      className={"form-control col-md-6"}
                      value={remDueRecurDetails?.NoOfInstallments}
                      onChange={(e) =>
                        handlePayRemainingDue(
                          e.target.value,
                          "NoOfInstallments"
                        )
                      }
                    />
                    {submitPayRemDue &&
                      showPayRemaingDueErrorMessage(
                        "NoOfInstallments",
                        "Installment"
                      )}
                  </div>
                </div>
                <div className="bz-content-text-row-new align-items-start  bz-notify-row">
                  <div className="bz-content-title text-right">
                    Installment Details
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {showRecurrenceDetails(payDueRecurrencetable)}
                  </div>
                </div>
              </>
            )}
            <div
              className="bz-content-text-row-new"
              style={{ fontWeight: 600 }}
            >
              <div className="bz-content-title text-right">
                {IsWaivePayment ? "Total Waived Amount" : "Pay Today"}
              </div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                {CurrencyFormat(payRemainingDue)}
              </div>
            </div>
            {!IsTaxSkipped && !IsWaivePayment && TotalTaxAmount > 0 && (
              <>
                <div
                  className="bz-content-text-row-new"
                  style={{ fontWeight: 600 }}
                >
                  <div className="bz-content-title text-right">
                    <Tooltip
                      content={(tooltipProps: any) => {
                        return (
                          <TooltipContentPackageService
                            title={tooltipProps.title}
                            data={Taxes}
                            TotalTaxAmount={TotalTaxAmount}
                          />
                        );
                      }}
                      tooltipClassName="bz-tooltip-table"
                    >
                      <a
                        title="Surcharges"
                        style={{
                          borderBottom: "1px dotted #0d6efd",
                          cursor: "pointer",
                        }}
                      >
                        {"Surcharges to be Collected"}
                      </a>
                    </Tooltip>
                  </div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {CurrencyFormat(TotalTaxAmount)}
                  </div>
                </div>
                <div
                  className="bz-content-text-row-new"
                  style={{ fontWeight: 600 }}
                >
                  <div className="bz-content-title text-right">{"Total"}</div>
                  <span className="bz-colon text-center">:</span>
                  <div className="bz-content-body">
                    {CurrencyFormat(TotalTaxAmount + payRemainingDue)}
                  </div>
                </div>
              </>
            )}
            <div className="bz-content-text-row-new mt-2 mble-mt-0">
              <div className="bz-content-title text-right"></div>
              <span className="bz-colon text-center"></span>
              <div className="bz-content-body">
                <Button
                  className="ml-0"
                  onClick={() => handleReset()}
                  disabled={payRemDueSubmitLoading || !submistatus?.payRemDue}
                >
                  Cancel
                </Button>
                <BuzopsButton
                  disabled={payRemDueSubmitLoading || !submistatus?.payRemDue}
                  type={"submit"}
                  loading={payRemDueSubmitLoading}
                  label={IsWaivePayment ? "Waive" : "Pay Now"}
                  onClick={() => submitPayRemainigDue()}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };
  const handleSuccessClosePaymethodDialog = async (successMsg=null,modeVal=null) => {
    setShowPaymentDialog(!showPaymentDialog);
    refreshPaymentProfiles(modeVal);
  };
  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
          className="bz-addCard-dialog"
          title={`${
            addNewItem !== "addOther"
              ? checkIfUserCanAddBankAccount()
                ? "Add Bank or Card"
                : "Add Card"
              : "Add Payment Mode"
          }`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={true}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={props?.userDetails?.UserMemberId}
          />
        </Dialog>
      </>
    );
  };
  return (
    <>
      {/* <PaymentMethods
        mainUserId={serviceInstance?.UserMemberId}
        // onPaymentMethodAddOrUpdate={() => onPaymentMethodAddOrUpdate()}
      /> */}
      {/* <br /> */}
      {/* <hr /> */}
      {renderPayRemainingDue()}
      {/* <div key={refreshComponent.toString()}>{renderActivateOrPayComponent()}</div> */}
      {showPaymentDialog && PaymethodDialog()}
      {showAdditionalPayment?.dialog && 
        <AdditionalPayment 
            fetchPaymentProfiles={fetchPaymentProfiles}
            userMemberId={props?.userDetails?.UserMemberId}
            setShowAdditionalPayment={setShowAdditionalPayment}
            paymentProfile={paymentProfile}
            PurchaseAmount={!IsTaxSkipped ? payRemainingDue + TotalTaxAmount : payRemainingDue}
            CreditAmount={selectedPaymentProfile?.Credit}
            showAdditionalPayment={showAdditionalPayment}
            handleChangeAdditionalPaymentProfile={handleChangeAdditionalPaymentProfile}
            handleAddAdditionalPayment={handleAddAdditionalPayment}
        />}
    </>
  );
};

export default withNotification(PayRemainingDue);
