import { DropDownList } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import React, { useState } from "react";
import { CurrencyFormat, checkIfUserAddPaymentGateway, checkIfUserCanAddBankAccount, paymentDropdownItems } from "utils";
import AddonsInPlanCancelOverview from "./AddonsInPlanCancelOverview";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { TooltipContentRefundTax } from "components/Transactions/Refund";
import { CancellationOnOptions } from "utils/form-utils";
import withNotification from "components/Hoc/withNotification";
import { Input } from "@progress/kendo-react-inputs";
import { Upload } from "@progress/kendo-react-upload";

const PlanCancelSummary = (props: any) => {
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);

const [addNewItem, setAddNewItem] = useState<any>(null);

const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };

  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog)
  }
  const handleSuccessClosePaymethodDialog = async (msg = null,modeVal = null) => {
    if (msg) {
      props?.handleNotificationMessage(msg, "success");
    }
    setShowPaymentDialog(!showPaymentDialog)
    await props?.fetchPaymentProfiles(props?.userData?.MainUserId,modeVal)
  }

  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
         className="bz-addCard-dialog"
         title={`${
          addNewItem !== "addOther"
            ? checkIfUserCanAddBankAccount()
              ? "Add Bank or Card"
              : "Add Card"
            : "Add Payment Mode"
        }`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation staffPage={props?.staffPage} addNewItem={addNewItem} handleClosePaymethodDialog={handleClosePaymethodDialog} handleSuccessClosePaymethodDialog={handleSuccessClosePaymethodDialog} UserMemberId={props?.userData?.UserMemberId} />
        </Dialog>
      </>
    );
  };
  const {
    PlanName,
    IsNeedToCancel,
    CancellationDate,
    CancellationFee,
    AmountToCollect,
    FailedTransactionAmountToCollect,
    AmountToRefund,
    WaivedFailedTransactionAmount,
    FailedTransactionAmount,
    AddOns,
    MetaData,
    CancellationOn,
    PaymentAttributes=null
  } = props?.cancelDetails;
  return (
    <>

      <div className="bz-mb-2_5rem bz-content-text-row-mobile">

        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Membership Name</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{PlanName}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Cancel On</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CancellationOnOptions?.filter(
                                            (i: any) => i?.id === CancellationOn
                                          )?.[0]?.text}</div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Cancellation Date</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            {CancellationDate === null
              ? "NA"
              : moment(CancellationDate).format("L")}
          </div>
        </div>
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Cancellation Fee</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CurrencyFormat(CancellationFee)}</div>
        </div>
        {FailedTransactionAmount > 0 && (
          <>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Total Failed Transaction Amount</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{CurrencyFormat(FailedTransactionAmount)}</div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Waived Failed Transaction Amount</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{CurrencyFormat(WaivedFailedTransactionAmount)}</div>
            </div>
            <div className="bz-content-text-row">
              <div className="bz-content-title text-right">Failed Transaction Amount To Collect</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{CurrencyFormat(FailedTransactionAmountToCollect)}</div>
            </div>


          </>
        )}
        {AmountToRefund >= 0 && (<>
          <div className="bz-content-text-row">
            <div className="bz-content-title text-right">Amount to Refund</div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{CurrencyFormat(AmountToRefund)}</div>
          </div>
          {AmountToRefund > 0 && MetaData && ( <div className="bz-content-text-row">
            <div className="bz-content-title text-right">
              <Tooltip
                content={(tooltipProps: any) => {
                  return (
                    <TooltipContentRefundTax
                      title={tooltipProps.title}
                      data={props?.cancelDetails?.TaxesArray}
                      TotalTaxAmount={props?.cancelDetails?.taxAmt}
                      Name={props?.cancelDetails.PlanName}
                    />
                  );
                }}
                tooltipClassName="bz-tooltip-table"
              >
                <a
                  title="Surcharges"
                  style={{
                    borderBottom: "1px dotted #0d6efd",
                    cursor: "pointer",
                  }}
                >
                  {"Surcharges to be refunded"}
                </a>
              </Tooltip></div>
            <span className="bz-colon text-center">:</span>
            <div className="bz-content-body">{CurrencyFormat(props?.cancelDetails?.taxAmt)}</div>
          </div>)}
          </>
        )}
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Amount to Collect</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">{CurrencyFormat(AmountToCollect)}</div>
        </div>
      </div>
      <hr />
      {AddOns?.length > 0 && <h5 className="mb-3 pt-3 bz_fs_1_5">Add-on Details</h5>}
      {AddOns?.map((addonItem: any, index: number) => {
        return (
          <>
            <AddonsInPlanCancelOverview addonItem={addonItem} />
          </>
        );
      })}
      {IsNeedToCancel && (
        <>
          <div className="bz-mt-2rem bz-mb-2_5rem">
            {props?.totalCancellationAmount > 0 && (
              <div className="bz-content-text-row align-items-start">
                <div className="bz-content-title text-right">Payment Profile</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body payment-profile">
                  <div>
                    <DropDownList
                      id={`PaymentGatewayPaymentProfileId`}
                      name={`PaymentGatewayPaymentProfileId`}
                      data={props?.paymentProfiles}
                      textField="MaskedCCNumber"
                      // dataItemKey="PaymentProfileID"
                      valueRender={(
                        el: React.ReactElement<HTMLSpanElement>,
                        value: any
                      ) => props?.valueRender(el, value, value)}
                      itemRender={props?.itemRender}
                      onChange={(e) => {
                        props?.handleChange(e.value, "paymentProfile");
                      }}
                      style={{ width: "100%" }}
                    />
                    {props?.showErrorMessage(
                      "PaymentGatewayPaymentProfileId",
                      "Payment Profile"
                    )}
                    </div>
                    {checkIfUserAddPaymentGateway() && <div className="addNewBtn">
                      <DropDownButton
                        text="Add New"
                        onItemClick={(e: any) => onCardOrBankAdd(e)}
                        items={paymentDropdownItems()?.filter((i:any)=>i?.visible===true)}
                        look="flat"
                        className="btn btn-link add-new-btn"
                      />
                    </div>}
                </div>
              </div>

            )}
                {props?.mainData?.CardTypeId === -2  && (
                <>
                  <div
                    className={`bz-content-text-row `}
                  >
                    <div className="bz-content-title text-right">
                      Transaction Reference Number
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      <div className={"k-form-field-wrap"}>
                        <Input
                          onChange={(event) =>
                            props?.handleChange(
                              {
                                Reference: event?.target?.value,
                                ReferrenceDocument: PaymentAttributes?.ReferrenceDocument,
                              }
                            ,"PaymentAttributes")
                          }
                          value={PaymentAttributes?.Reference}
                          type={"text"}
                          style={{ width: "280px" }}
                          id={"refNumber"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`bz-content-text-row`}>
                    <div className="bz-content-title text-right">
                    Reference Document
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      <div className={"k-form-field-wrap"}>
                        {/* <input type="file" /> */}
                        <Upload
                          autoUpload={false}
                          batch={false}
                          restrictions={{
                            // allowedExtensions: [".jpg", ".png", ".jpeg"],
                            maxFileSize: 1000000,
                          }}
                          showActionButtons={false}
                          multiple={false}
                          onAdd={props?.onAdd}
                          withCredentials={false}
                          onRemove={props?.onRemove}
                          files={props?.originalFiles}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

            <div className="bz-content-text-row bz-notify-row align-items-start">
              <div className="bz-content-title text-right">Comments</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">
                <textarea
                  className="form-control"
                  onChange={(e) =>
                    props?.handleChange(e.target.value, "Comments")
                  }
                ></textarea>
                {props?.showErrorMessage("Comments", "Comments")}
              </div>
            </div>
            <div className="bz-content-text-row bz-notify-row">
              <div className="bz-content-title text-right">Total Amount to Collect</div>
              <span className="bz-colon text-center">:</span>
              <div className="bz-content-body">{CurrencyFormat(props?.totalCancellationAmount)}</div>
            </div>
          </div>
          {showPaymentDialog && PaymethodDialog()}
        </>
      )}

    </>
  );
};

export default withNotification(PlanCancelSummary)
