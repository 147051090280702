import React, { useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import { CurrencyFormat, checkIfUserAddPaymentGateway, checkIfUserCanAddBankAccount, paymentDropdownItems } from "utils";
import PrimaryMember from "../../../PrimaryMember.png";
import SubMember from "../../../SubMember.png";
import { DropDownButton } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import PaymentModalCreation from "components/PaymentCheckout/PaymentModalCreation";
import withNotification from "components/Hoc/withNotification";
import { Upload } from "@progress/kendo-react-upload";
import { Input } from "@progress/kendo-react-inputs";
const PlanFreezeSummary = (props: any) => {
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);

  const [addNewItem, setAddNewItem] = useState<any>(null);
  
  const onCardOrBankAdd = (e: any) => {
    setShowPaymentDialog(true);
    setAddNewItem(e.item.key);
  };

  const handleClosePaymethodDialog = async () => {
    setShowPaymentDialog(!showPaymentDialog);
  };
  const handleSuccessClosePaymethodDialog = async (msg = null,modeVal = null) => {
    if (msg) {
      props?.handleNotificationMessage(msg, "success");
    }
    setShowPaymentDialog(!showPaymentDialog);
    await props?.fetchPaymentProfiles(props?.userData?.MainUserId,modeVal);
  };

  const PaymethodDialog = () => {
    return (
      <>
        <Dialog
         className="bz-addCard-dialog"
         title={`${
          addNewItem !== "addOther"
            ? checkIfUserCanAddBankAccount()
              ? "Add Bank or Card"
              : "Add Card"
            : "Add Payment Mode"
        }`}
          onClose={() => handleClosePaymethodDialog()}
          style={{ zIndex: 9999 }}
        >
          <PaymentModalCreation
            staffPage={props?.staffPage}
            addNewItem={addNewItem}
            handleClosePaymethodDialog={handleClosePaymethodDialog}
            handleSuccessClosePaymethodDialog={
              handleSuccessClosePaymethodDialog
            }
            UserMemberId={props?.userData?.UserMemberId}
          />
        </Dialog>
      </>
    );
  };

  return (
    <>
      <h5 className="mb-3 pt-3 bz_fs_1_5">Membership Details</h5>
      <div className="bz-mt-2rem bz-content-text-row-mobile">
        <div className="bz-content-text-row">
          <div className="bz-content-title text-right">Client Name</div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            <div className="d-flex align-items-center">
              {props?.selectedPlan?.MemberType === 0 ? (
                <img src={PrimaryMember} className="bz-member-icon" />
              ) : (
                <img src={SubMember} className="bz-member-icon" />
              )}
              {`${props?.selectedPlan?.MemberName}`}
            </div>
          </div>
        </div>
      </div>
      {props?.freezeDetails?.Result?.map((dataItem: any) => {
        const {
          EntityType,
          EntityName,
          ServiceType,
          DurationType,
          Duration,
          EndDate,
          ImmediateFreeze,
          ImmediateFreezeStartDate,
          NormalFreezeStartDate,
          FreezeRate,
          MaximumBillingCycleOnFreeze,
          Occurrence,
          Frequency,
          FrequencyDescription,
          RepeatFrequency,
        } = dataItem;
        return (
          <>
            <div className={`bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile ${ServiceType}`}>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">
                  {ServiceType} Name
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">{EntityName}</div>
              </div>
              {EntityType === 1 && (
                <>
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right">Duration</div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      {DurationType === 1 ? `${Duration} Months` : "NA"}
                    </div>
                  </div>
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right">End Date</div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      {DurationType === 1 ? moment(EndDate).format("L") : "NA"}
                    </div>
                  </div>
                </>
              )}
              {EntityType === 4 && (
                <>
                    <div className="bz-content-text-row">
                      <div className="bz-content-title text-right">
                        Repeat Every
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">{`${Frequency === 1 ? "NA" : RepeatFrequency} - ${FrequencyDescription}`}</div>
                    </div>
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right">End</div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      {Occurrence > 0
                        ? `After ${Occurrence} Occurrences`
                        : "as never"}
                    </div>
                  </div>
                </>
              )}
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">
                  Freeze Start Date
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  {ImmediateFreeze > 0
                    ? moment(ImmediateFreezeStartDate).format("L")
                    : moment(NormalFreezeStartDate).format("L")}
                </div>
              </div>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">
                  Maximum Months on Freeze
                </div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  {MaximumBillingCycleOnFreeze}
                </div>
              </div>
              <div className="bz-content-text-row">
                <div className="bz-content-title text-right">Freeze Fee</div>
                <span className="bz-colon text-center">:</span>
                <div className="bz-content-body">
                  {CurrencyFormat(FreezeRate)}
                </div>
              </div>
            </div>
            <hr />
          </>
        );
      })}
      <div className="bz-mt-2rem bz-mb-2_5rem">
        {props?.freezeDetails?.Result[0]?.FreezeRate > 0 ?   <div className="bz-content-text-row align-items-start bz-notify-row">
          <div className="bz-content-title text-right">Payment Profile<span className="required-asterisk">*</span></div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body payment-profile">
            <div>
              <DropDownList
                id={`PaymentGatewayProfileId`}
                name={`PaymentGatewayProfileId`}
                data={props?.paymentProfiles}
                textField="MaskedCCNumber"
                valueRender={(
                  el: React.ReactElement<HTMLSpanElement>,
                  value: any
                ) => props?.valueRender(el, value)}
                itemRender={props?.itemRender}
                onChange={(e) => {
                  props?.handleChange(e.value, "paymentProfile", 0);
                }}
                style={{ width: "100%" }}
              />
              {props?.showErrorMessage(
                "PaymentGatewayProfileId",
                "Payment Profile"
              )}
            </div>
            {checkIfUserAddPaymentGateway() && <div className="addNewBtn">
              <DropDownButton
                text="Add New"
                onItemClick={(e: any) => onCardOrBankAdd(e)}
                items={paymentDropdownItems()?.filter((i:any)=>i?.visible===true)}
                look="flat"
                className="btn btn-link add-new-btn"
              />
            </div>}
          </div>
        </div>:null}
        {props?.freezeDetails?.CardTypeId === -2  && (
                <>
                <div
                    className={`bz-content-text-row`}
                  >
                    <div className="bz-content-title text-right">
                      Transaction Reference Number
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      <div className={"k-form-field-wrap"}>
                      <Input
                          onChange={(event) =>
                            props?.handleChangePaymentAttributes(
                              {
                                Reference: event?.target?.value,
                                ReferrenceDocument: props?.paymentAttributes?.ReferrenceDocument,
                              })
                          }
                          value={props?.paymentAttributes?.Reference}
                          type={"text"}
                          style={{ width: "100%" }}
                          id={"refNumber"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={`bz-content-text-row`}>
                    <div className="bz-content-title text-right">
                    Reference Document
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      <div className={"k-form-field-wrap"}>
                        {/* <input type="file" /> */}
                        <Upload
                          autoUpload={false}
                          batch={false}
                          restrictions={{
                            // allowedExtensions: [".jpg", ".png", ".jpeg"],
                            maxFileSize: 1000000,
                          }}
                          showActionButtons={false}
                          multiple={false}
                          onAdd={props?.onAdd}
                          withCredentials={false}
                          onRemove={props?.onRemove}
                          files={props?.originalFiles}
                        />
                      </div>
                    </div>
                  </div></>
        )}
        <div className="bz-content-text-row align-items-start bz-notify-row">
          <div className="bz-content-title text-right">Comments<span className="required-asterisk">*</span></div>
          <span className="bz-colon text-center">:</span>
          <div className="bz-content-body">
            <textarea
              className="form-control"
              placeholder="Please Enter Comments"
              onChange={(e) =>
                props?.handleChange(e.target.value, "Comments", 0)
              }
            ></textarea>
            {props?.showErrorMessage("Comments", "Comments")}
          </div>
        </div>
      </div>
      {showPaymentDialog && PaymethodDialog()}
    </>
  );
};

export default withNotification(PlanFreezeSummary)
