import { filterBy } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList, DropDownListFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import withNotification from "components/Hoc/withNotification";
import BuzopsButton from "generic-components/BuzopsButton";
import React, { useEffect, useState } from "react";
import { useApiClient } from "services/axios-service-utils";
import { TenantService } from "services/tenant/index.service";

const ShowAddOtherPaymentMode = (props: any) => {
  const { axiosRequest } = useApiClient();
  const [description, setDescription] = useState("");
  const [cardSubmitBtnLoading, setCardSubmitBtnLoading] = useState(false);

  const [categoryDropdownOpened, setCategoryDropdownOpened] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [filteredCategories, setFilteredCategories] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [allCategories, setAllCategories] = useState<any>([]);
  const [loading, setLoading] = useState(true);


  useEffect(()=>{
    getCategory()
  },[])

  
  const handleCategoryChange = (val: any) => {
    setSelectedCategory(val);
    setCategoryDropdownOpened(false);
    props?.handleAddPaymentMode(
      val?.Description
    );

  };

  const getCategory = async () => {
    setLoading(true);
    try {
      const service = new TenantService();
      const type=2
      const res = await service.getCategory(type);
      setAllCategories(res);
      setFilteredCategories(res);
    } finally {
      setLoading(false);
    }
  };

  
  const handleFilterChange = (filter: any) => {
    const data = allCategories.slice();
    return filterBy(data, filter);
  };
  
  const handleSearchChange = (e: DropDownListFilterChangeEvent) => {
    const filteredData = handleFilterChange(e.filter);
    setFilteredCategories(filteredData);
    setSearchValue(e.filter.value);
  };

  const noDataRender = () => {
    const isDisabled = !searchValue.trim();

    return (
      <div style={{ padding: "10px 5px" }}>
        <Button
          primary={false}
          onClick={handleSave}
          className="btn-sm-addnewclient"
          disabled={isDisabled}
        >
          <i className="fa fa-plus"></i>
          Add New
        </Button>
      </div>
    );
  };

  const handleInputChange = (e: any) => {
    setDescription(e.target.value);
  };

  const handleSave = async () => {
    const description = searchValue?.trim()
    console.log("Description Value:", description);
    setCardSubmitBtnLoading(true);
    const req = {
      Type: 2,
      Name: description,
      Description: description,
    };

    await axiosRequest.post("productcategory", req, {
      successCallBack: async (response: any) => {
        setCardSubmitBtnLoading(false);
        props?.handleAddPaymentMode(
          description
        );
      },
      errorCallBack: (error: any) => {
        setCardSubmitBtnLoading(false);
        const errorMsg =
          error?.response?.data?.Messages?.[0] ||
          error?.data?.Messages?.[0] ||
          "Internal Server Error";
        props?.handleNotificationMessage(errorMsg, "error");
      },
    });
  };
  return (
    <div className="bz-add-card-field">
      <div className="payment-card-input mt-2 mb-3">
        {/* <Input
          type={"text"}
          placeholder={"Enter Payment Mode Category"}
          style={{ width: "300px" }}
          id="Description"
          value={description}
          onChange={handleInputChange}
        /> */}
            <DropDownList
                      style={{ width: "280px" }}
                      data={filteredCategories}
                      listNoDataRender={noDataRender}
                      value={
                        selectedCategory || {
                          ProductCategoryId: 0,
                          Name: "Select payment mode",
                        }
                      }
                      onChange={(e) => handleCategoryChange(e.target.value)}
                      textField="Name"
                      dataItemKey="ProductCategoryId"
                      filterable={true}
                      opened={categoryDropdownOpened}
                      onOpen={() => setCategoryDropdownOpened(true)}
                      onClose={() => setCategoryDropdownOpened(false)}
                      onFilterChange={handleSearchChange}
                      filter={searchValue}
                    />

      </div>
      {/* <div className="d-flex col-12 pt-4 pb-2">
        <BuzopsButton
          primary
          label={"Continue"}
          disable={!description}
          loading={cardSubmitBtnLoading}
          onClick={handleSave}
        />
      </div> */}
    </div>
  );
};

export default withNotification(ShowAddOtherPaymentMode)
