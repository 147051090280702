import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Error } from "@progress/kendo-react-labels";
import { Notification as Notify } from "@progress/kendo-react-notification";
import withNotification from "components/Hoc/withNotification";
import {
  PaymentItemRender,
  PaymentValueRender,
} from "components/Payment/PaymentRenderItems";
import BuzopsButton from "generic-components/BuzopsButton";
import BuzopsLoader from "generic-components/BuzopsLoader";
import React, { useEffect, useState } from "react";
import { TenantService } from "services/tenant/index.service";
import { CurrencyFormat } from "utils";
import FailedTransactionsGrid from "../FailedTransactionsGrid";
import ActivateSuspendedSummary from "./ActivateSuspendedSummary";
import PlanAddonActivate from "./PlanAddonActivate";
import { CreditsErrorMessage } from "utils/form-utils";
import { UploadFileInfo, UploadOnAddEvent, UploadOnRemoveEvent } from "@progress/kendo-react-upload";

const ActivateSuspended = (props: any) => {
  const [activationDetails, setActivationDetails] = useState<any>({});
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [paymentProfiles, setPaymentProfiles] = useState<any>([]);
  const [pageType, setPageType] = useState("plan");
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [originalFiles, setOriginalFiles] = useState<Array<UploadFileInfo>>([]);
  const [files, setFiles] = useState<Array<any>>([]);
  const service = new TenantService();

  useEffect(() => {
    fetchAllApis();
  }, []);


  useEffect(() => {
    constructFileWithBas64(originalFiles);
  }, [originalFiles]);


  const constructFileWithBas64 = (records: any) => {
    let activationDataItem=null
    if(activationDetails){
      activationDataItem = JSON.parse(JSON.stringify(activationDetails));
    }
    if (records.length > 0) {
      let fileData: any = [];
      records.forEach((file: any) => {
        const reader: any = new FileReader();
        const test = {
          Type: 1,
          ImageAsBase64: null,
          FileName: file?.name,
          Extension: file?.extension,
          Size: file?.size,
          InternalDataId: file?.uid,
        };
        reader.onloadend = function () {
          // Since it contains the Data URI, we should remove the prefix and keep only Base64 string
          fileData.push({ ...test, ImageAsBase64: reader?.result });
          setFiles(fileData);
          if(activationDataItem){
            handleChange(
              {
                Reference: activationDataItem?.PaymentAttributes?.Reference,
                ReferrenceDocument: fileData?.[0],
              }
            ,"PaymentAttributes",0)
          }
        }
        reader.readAsDataURL(file.getRawFile());
      });
    } else {
      setFiles([]);
      if(activationDataItem){
        handleChange({
            Reference: activationDataItem?.PaymentAttributes?.Reference,
            ReferrenceDocument: null,},"PaymentAttributes",0)
      }
      }
  };

  const onAdd = (event: UploadOnAddEvent) => {
    setOriginalFiles(event.newState);
    
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setOriginalFiles(event.newState);
  };

  const fetchAllApis = async () => {
    setLoading(true);
    await fetchPlanSummary();
    await fetchPaymentProfiles(props?.userData?.MainUserId);
    setLoading(false);
  };
  const refreshPaymentProfiles = (modeVal=null) => {
    fetchPaymentProfiles(props?.userData?.MainUserId,modeVal)
  }
  async function fetchPaymentProfiles(userMemberId: any,modeVal=null) {
    const result = await service.paymentProfile(userMemberId);

    if(modeVal){
      const record=result?.find((i:any)=> i?.CardTypeId ===-1)
      if(record){
        const data={
          ...record,
          CardTypeId: -2,
          MaskedCCNumber: modeVal,
          CardDescription: modeVal,
          CardDescriptionWithoutExpiryDate: modeVal,
        };
        const res=[...result]
        res.push(data)
        setPaymentProfiles(JSON.parse(JSON.stringify(res)))
        handleChange(data, "paymentProfile",0)
      }
    }else{
      setPaymentProfiles(result || []);
    }
  }

  const fetchPlanSummary = async () => {
    const req = {
      PageNumber: 0,
      PageSize: 32767,
      UserMemberId: props?.userData?.UserMemberId,
      MemberPlanId: props?.selectedPlanId,
    };
    const res = await service?.getMemberPlanSummary(req);
    setSelectedPlan(res);
    await fetchActivationDetails(res?.UserMemberId);
  };

  const fetchActivationDetails = async (UserMemberId: string) => {
    const MemberPlanId = props.selectedPlanId;
    const req = {
      MemberPlanId: MemberPlanId,
      UserMemberId: UserMemberId,
    };

    const result = await service.GetPlanActivationDetails(req);
    setActivationDetails(result);
  };


  const selectionChange = (event: any, EntityId: number) => {
    const dataItem = JSON.parse(JSON.stringify(activationDetails));
    let updateDetails = dataItem;
    if (EntityId === 0) {
      const originalDup = dataItem?.FailedPayments;
      const original = originalDup.map((item: any) => {
        if (
          item?.PaymentGatewayTransactionHistoryId ===
          event?.dataItem?.PaymentGatewayTransactionHistoryId
        ) {
          item.CanWaive = !event.dataItem.CanWaive;
        }
        return item;
      });
      updateDetails = {
        ...dataItem,
        FailedPayments: original,
      };
    } else {
      const originalDup = dataItem?.Subscriptions;

      const original = originalDup.map((item: any) => {
        if (item?.SubscriptionId === EntityId) {
          const failedTransc = item?.FailedPayments.map((childItem: any) => {
            if (
              childItem?.PaymentGatewayTransactionHistoryId ===
              event?.dataItem?.PaymentGatewayTransactionHistoryId
            ) {
              childItem.CanWaive = !event.dataItem.CanWaive;
            }
            return { ...childItem };
          });
          return { ...item, FailedPayments: failedTransc };
        } else {
          return { ...item };
        }
      });

      updateDetails = {
        ...dataItem,
        Subscriptions: original,
      };
    }

    setActivationDetails(updateDetails);
  };

  const handleNext = async () => {
    setBtnLoading(true);
    const result = await service.UpdatePlanActivation(
      activationDetails
    );
    setBtnLoading(false);
    if (result?.ResponseCode === 100) {
      setPageType("final");
      setActivationDetails(result?.Item);
    }
  };

  const showErrorMessage = (key: any, mainKey: any, type = "freeze") => {
    if (
      key === "PaymentGatewayProfileId" &&
      (!activationDetails[key] || activationDetails[key] === 0)
    ) {
      return <Error>{`${mainKey} is required`}</Error>;
    } else if (
      key !== "PaymentGatewayProfileId" &&
      (!activationDetails[key] || activationDetails[key].length <= 0)
    ) {
      return <Error>{`${mainKey} is required`}</Error>;
    }
    return <></>;
  };

  const handleValidation = () => {
    if (activationDetails.TotalAmount > 0 && (
      !activationDetails?.PaymentGatewayPaymentProfileId ||
      activationDetails?.PaymentGatewayPaymentProfileId === 0)
    ) {
      return false;
    }else  if(activationDetails?.CardTypeId === 8 && activationDetails?.TotalAmount > activationDetails?.Credit){
      const errorMsg = CreditsErrorMessage
      props?.handleNotificationMessage(errorMsg, "error");
      return false
    }
    return true;
  };

  const handleSubmitActivation = async () => {
    const check = handleValidation();
    if (check) {
      setBtnLoading(true);
      const result = await service.CompletePlanActivation(activationDetails);
      setBtnLoading(false);
      if (result?.ResponseCode === 100) {
        props?.handleNotificationMessage(result?.SuccessMessage, 'success');
        setPageType("plan");
        // await fetchAllApis();
        //props?.onActivate?.()
        setBtnLoading(true);

        const req = {
          PageNumber: 0,
          PageSize: 32767,
          UserMemberId: props?.userData?.UserMemberId,
          MemberPlanId: props?.selectedPlanId,
        };
        const res = await service?.getMemberPlanSummary(req);
        setBtnLoading(false);
  
        props?.reopenPlan(res?.MemberPlanId,res?.planStatusDescription,
          "plan",
          res);
      } else {
        const errorMsg =
          result?.ErrorMessages.length > 0
            ? result?.ErrorMessages[0]
            : "Error in Activating Membership";
        props?.handleNotificationMessage(errorMsg, 'error');
      }
    }
  };
  const handleChange = async (value: any, name: string, entityId: number) => {
    const data = JSON.parse(JSON.stringify(activationDetails));
    let activationDet = data;
    if (name === "paymentProfile") {
      const PaymentGatewayPaymentProfileID =
        value?.PaymentGatewayPaymentProfileID;
      activationDet = {
        ...data,
        PaymentGatewayPaymentProfileId: PaymentGatewayPaymentProfileID,
        CardTypeId:value?.CardTypeId,
        Credit:value?.Credit,
        MaskedCCNumber:value?.MaskedCCNumber
      };
    }
    else if(name==="PaymentAttributes"){
      let paymentAttributes = null;
      if (data?.CardTypeId === -2) {
        paymentAttributes = {
          Reference: value?.Reference,
          OfflinePaymentDescription: data?.MaskedCCNumber,
          ReferrenceDocumentName: value?.ReferrenceDocument?.FileName || "",
          ReferrenceDocumentContent: value?.ReferrenceDocument?.ImageAsBase64 || "",
        };
      }
      activationDet = {
        ...data,
        [name]: paymentAttributes,
      };
    } else if (entityId === 0) {
      activationDet = {
        ...data,
        [name]: value,
      };
    } else if (entityId > 0) {
      const resultItems = activationDetails?.Subscriptions?.map((i: any) => {
        if (i?.SubscriptionId === entityId) {
          return { ...i, [name]: value };
        } else {
          return { ...i };
        }
      });
      activationDet = {
        ...data,
        Subscriptions: resultItems,
      };
    }
    setActivationDetails(activationDet);
  };
  if (loading) {
    return <BuzopsLoader type="list" />
  }
  if (!activationDetails) {
    return <div className="bz-norecords-available">{"No Data Found"}</div>;
  }
  const {
    Subscriptions,
    PlanName,
    MonthlyRate,
    ProratedAmount,
    FailedPayments,
    PenaltyAmount,
    DurationType
  } = activationDetails;
  const selectedPaymentProfile=paymentProfiles?.find((i:any)=> i?.PaymentGatewayPaymentProfileID===activationDetails?.PaymentGatewayPaymentProfileId && i?.CardTypeId ===activationDetails?.CardTypeId)
  return (
    <>
      <div className="suspend-container">
        <h5 className="mb-3 bz_fs_1_5">Activation Details</h5>
        <hr />
        <div className="bz-subsection-row bz-plan-overview bz-addon-cancellation">
          <div className="left-section">
            {pageType === "final" && (
              <ActivateSuspendedSummary
                activationDetails={activationDetails}
                selectedPlan={selectedPlan}
                userData={props?.userData}
                paymentProfiles={paymentProfiles}
                valueRender={(
                  el: React.ReactElement<HTMLSpanElement>,
                  value: any
                ) => PaymentValueRender(el, value, selectedPaymentProfile)}
                itemRender={PaymentItemRender}
                showErrorMessage={(key: any, mainKey: any) =>
                  showErrorMessage(key, mainKey)
                }
                handleChange={(val: any, name: string, entityId: number) =>
                  handleChange(val, name, entityId)
                }
                refreshPaymentProfiles={refreshPaymentProfiles}
                canAddCardOrBank={props?.canAddCardOrBank}
                onAdd={onAdd}
                onRemove={onRemove}
                originalFiles={originalFiles}
              />
            )}
            {pageType === "plan" && (
              <>
                <h5 className="mb-3 pt-3 bz_fs_1_5">Membership Details</h5>
                <div className="bz-mt-2rem bz-mb-2_5rem bz-content-text-row-mobile">
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right">Membership Name</div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">{PlanName}</div>
                  </div>

                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right">
                     {DurationType === 3 ? "Rate":"Monthly Rate"}
                    </div>
                    <span className="bz-colon text-center">:</span>
                    <div className="bz-content-body">
                      {CurrencyFormat(MonthlyRate)}
                    </div>
                  </div>
                  {ProratedAmount > 0 && (
                    <div className="bz-content-text-row">
                      <div className="bz-content-title text-right">
                        {DurationType === 3 ? "Prorated Rate":"Prorated Monthly Rate"}
                      </div>
                      <span className="bz-colon text-center">:</span>
                      <div className="bz-content-body">
                        {CurrencyFormat(ProratedAmount)}
                      </div>
                    </div>
                  )}
                  <div className="bz-content-text-row bz-notify-row">
                    <div className="bz-content-title text-right"></div>
                    <span className="bz-colon text-center"></span>
                    <div className="bz-content-body">
                      <div className="bz-plan-note">
                        <p className="mb-3 mb-md-0">
                          <Notify key="info" type={{ style: "info" }}>
                            {
                              "System will extend the plan end date since plan was in Suspended status"
                            }
                          </Notify>
                        </p>
                      </div>
                    </div>
                  </div>
                  {FailedPayments?.length > 0 && pageType === "plan" && (
                    <FailedTransactionsGrid
                      details={activationDetails?.FailedPayments}
                      selectionChange={(e: any, id: number) =>
                        selectionChange(e, id)
                      }
                      EntityId={0}
                    />
                  )}
                </div>
                {ProratedAmount > 0 && (
                  <>
                    <div className="bz-mb-2_5rem">
                      <div className="bz-content-text-row">
                        <div className="bz-content-title text-right">Penalty</div>
                        <span className="bz-colon text-center">:</span>
                        <div className="bz-content-body">
                          <NumericTextBox
                            id={`PenaltyAmount`}
                            name={`PenaltyAmount`}
                            min={0}
                            format={"c2"}
                            className="k-dropdown-width-100"
                            value={PenaltyAmount}
                            onChange={(e) =>
                              handleChange(e.target.value, "PenaltyAmount", 0)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                )}


                {Subscriptions?.length > 0 && (
                  <>
                    <h5 className="mb-3 pt-3 bz_fs_1_5">Add-on Details</h5>
                    <PlanAddonActivate
                      activationDetails={activationDetails}
                      userData={props?.userData}
                      showErrorMessage={(key: any, mainKey: any) =>
                        showErrorMessage(key, mainKey)
                      }
                      selectionChange={(e: any, id: number) =>
                        selectionChange(e, id)
                      }
                      handleChange={(val: any, name: string, entityId: number) =>
                        handleChange(val, name, entityId)
                      }
                    />
                  </>
                )}
              </>
            )}
            <div className="bz-mt-2rem bz-mb-2_5rem">
              {pageType === "plan" && (
                <>
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right"></div>
                    <span className="bz-colon text-center"></span>
                    <div className="bz-content-body">
                      <BuzopsButton
                        label={"Next"}
                        loading={btnLoading}
                        onClick={() => handleNext()}
                      />
                    </div>
                  </div>
                </>
              )}

              {pageType === "final" && (
                <>
                  <div className="bz-content-text-row">
                    <div className="bz-content-title text-right"></div>
                    <span className="bz-colon text-center"></span>
                    <div className="bz-content-body">
                      <BuzopsButton
                        label={"Previous"}
                        onClick={() => setPageType("plan")}
                      />
                      <BuzopsButton
                        label={"Complete"}
                        loading={btnLoading}
                        onClick={() => handleSubmitActivation()}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withNotification(ActivateSuspended);
