import React from "react";
import { CurrencyFormat } from "utils";
import { BsCCircleFill } from "react-icons/bs";
import { BuzopsTooltip } from "generic-components/BuzopsTooltip";
import { FaExclamationTriangle } from "react-icons/fa";

export const PaymentItemRender = (li: any, itemProps: any) => {
  let itemChildren = <></>;
  let showCredits = false;
  let icon = itemProps?.dataItem?.IsCreditCard && itemProps?.dataItem?.CardTypeId !==0
    ? "fa fa-credit-card"
    : `fa fa-university ${
        itemProps?.dataItem?.BankAccountType === 1 && "savingBankAccount"
      }`;
  let ExpiryDate = itemProps?.dataItem?.IsCreditCard
    ? itemProps.dataItem?.ExpiryDate
    : "";
  let value = itemProps?.dataItem?.MaskedCCNumber || "";
  if (itemProps?.dataItem?.CardTypeId === -1) {
    icon = "fa fa-dollar-sign";
    value = itemProps?.dataItem?.CardDescriptionWithoutExpiryDate;
    ExpiryDate = "";
  }else if (itemProps?.dataItem?.CardTypeId === -2) {
    icon = "fa fa-money-bill";
    value = itemProps?.dataItem?.CardDescriptionWithoutExpiryDate;
    ExpiryDate = "";
  } else if (itemProps?.dataItem?.CardTypeId === 8) {
    icon = "";
    value = "Credits Balance";
    ExpiryDate = "";
    if (itemProps?.dataItem?.Credit > 0) {
      showCredits = true;
    }
  }

  itemChildren =
    itemProps?.dataItem?.CardTypeId === 8 ? (
      showCredits ? (
        <span>
          <BsCCircleFill style={{fontSize:'1rem'}}/>&nbsp;
          {value} &nbsp;&nbsp;
          {`${CurrencyFormat(itemProps?.dataItem?.Credit)}`}
        </span>
      ) : null
    ) : (
      <span className="d-flex align-items-center">
        <i className={icon} aria-hidden="true"></i>&nbsp;{value}{" "}
        &nbsp;&nbsp;{ExpiryDate}  {itemProps?.dataItem?.Status ==="ACL"  || itemProps?.dataItem?.ProfileStatus ==="ACL" ? <BuzopsTooltip
          html={
            <span className="pl-1" title="The account has been closed, contact card holder for new card">
              <FaExclamationTriangle  style={{ color: 'red', marginTop: '-5px' }}/>
            </span>
          }
        />:null}
      </span>
    );
  return itemChildren ? React.cloneElement(li, li.props, itemChildren): null
};
export const PaymentValueRender = (
  element: React.ReactElement<HTMLSpanElement>,
  value: any,
  selectedPaymentProfile: any
) => {
  let children: any = [];
  if (selectedPaymentProfile?.MaskedCCNumber) {
    let icon = selectedPaymentProfile?.IsCreditCard  && selectedPaymentProfile?.CardTypeId !==0
      ? "fa fa-credit-card"
      : `fa fa-university ${
          selectedPaymentProfile?.BankAccountType === 1 && "savingBankAccount"
        }`;
    const ExpiryDate = selectedPaymentProfile?.IsCreditCard
      ? selectedPaymentProfile?.ExpiryDate
      : "";
    let val = selectedPaymentProfile?.MaskedCCNumber;

    if (selectedPaymentProfile?.CardTypeId === -1) {
      icon = "fa fa-dollar-sign";
      val = selectedPaymentProfile?.CardDescriptionWithoutExpiryDate;
    }
    if (selectedPaymentProfile?.CardTypeId === -2) {
      icon = "fa fa-money-bill";
      val = selectedPaymentProfile?.CardDescriptionWithoutExpiryDate;
    }
    if (selectedPaymentProfile?.CardTypeId === 8) {
      children = [
        <span>
          <BsCCircleFill style={{ fontSize: '1rem' }} />
          &nbsp;{"Credits Balance"} &nbsp;&nbsp;
          {CurrencyFormat(selectedPaymentProfile?.Credit)}
        </span>,
      ];
    } else {
      children = [
        <span key={icon} className="d-flex align-items-center">
          <i className={icon} aria-hidden="true"></i>&nbsp;{val}{" "}
          &nbsp;&nbsp;{ExpiryDate}{selectedPaymentProfile?.Status ==="ACL" || selectedPaymentProfile?.ProfileStatus ==="ACL" ? <BuzopsTooltip
            html={
              <span className="pl-1" title="The account has been closed, contact card holder for new card">
                <FaExclamationTriangle style={{ color: 'red', marginTop: '-5px' }} />

              </span>
            }
          /> : null}
        </span>,
      ];
    }
  } else {
    children = [<span>Please Select</span>];
  }

  return React.cloneElement(element, { ...element.props }, children);
};
